import { ConfigProvider } from "antd";
import { NotificationContextProvider } from "contexts/NotificationContext";
import DashBoardLayout from "layouts/DashBoardLayout";
import Account from "pages/Account";
import Dashboard from "pages/Dashboard";
import Status404 from "pages/ErrorPage/Status404";
import Forget from "pages/Forget/Forget";
import Reset from "pages/Reset/Reset";
import SignIn from "pages/SignIn/SignIn";
import UserDetail from "pages/UserDetail";
import Notification from "pages/Settings/Notification";
import User from "pages/Users";
import ProtectedRoute from "routes/ProtectedRoute";
import { BrowserRouter, Route, Routes } from "react-router-dom";
import { RouteName } from "routes";
import UserBusiness from "pages/UserBusiness";
import UserCashIn from "pages/UserCashIn";
import UserCashOut from "pages/UserCashOut";
import UserDebt from "pages/UserDebt";
import UserInventory from "pages/UserInventory";
import UserCustomer from "pages/UserCustomer";
import UserSuppler from "pages/UserSuppler";
import Business from "pages/Business";
import Debt from "pages/Debt";
import Inventory from "pages/Inventory";
import Person from "pages/Person";
import Transaction from "pages/Transaction";
import Country from "pages/Country";
const primaryColor = "#0000A1";
function App() {
  return (
    <ConfigProvider
      theme={{
        token: {
          colorPrimary: primaryColor,
        },
        components: {
          Pagination: {
            colorPrimaryHover: primaryColor,
            colorBgContainer: primaryColor,
            colorPrimaryBorder: primaryColor,
            colorText: "#464646",
            borderRadius: 4,
            fontSize: 16,
          },
          Select: {
            controlHeight: 45.6,
          },
        },
      }}
    >
      <NotificationContextProvider>
        <BrowserRouter>
          <Routes>
            <Route path="/" element={<SignIn />} />
            <Route path={RouteName.SignIn} element={<SignIn />} />
            <Route path={RouteName.ForgetPassword} element={<Forget />} />
            <Route path={RouteName.RestPassword} element={<Reset />} />
            <Route path="/" element={<ProtectedRoute />}>
              <Route path="" Component={DashBoardLayout}>
                <Route path={RouteName.DashBoard} element={<Dashboard />} />
                <Route path={RouteName.Accounts} element={<Account />} />
                <Route path={RouteName.Users} element={<User />} />
                <Route path={RouteName.Country} element={<Country />} />
                <Route path={RouteName.UserDetail} element={<UserDetail />} />
                <Route
                  path={RouteName.UserBusiness}
                  element={<UserBusiness />}
                />
                <Route path={RouteName.UserCashIn} element={<UserCashIn />} />
                <Route path={RouteName.UserCashOut} element={<UserCashOut />} />
                <Route path={RouteName.UserDebt} element={<UserDebt />} />
                <Route
                  path={RouteName.UserInventory}
                  element={<UserInventory />}
                />
                <Route
                  path={RouteName.UserCustomer}
                  element={<UserCustomer />}
                />
                <Route
                  path={RouteName.UserSuppliers}
                  element={<UserSuppler />}
                />
                <Route path={RouteName.Businesses} element={<Business />} />
                <Route path={RouteName.Debt} element={<Debt />} />
                <Route path={RouteName.Inventory} element={<Inventory />} />
                <Route path={RouteName.Person} element={<Person />} />
                <Route path={RouteName.Transaction} element={<Transaction />} />
                <Route path={RouteName.Setting} element={<Notification />} />
              </Route>
            </Route>
            <Route path="*" element={<Status404 />} />
          </Routes>
        </BrowserRouter>
      </NotificationContextProvider>
    </ConfigProvider>
  );
}

export default App;

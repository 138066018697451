import { Avatar, Table, TableColumnsType } from "antd";
import { BankOutlined } from "@ant-design/icons";
import SearchInput from "components/Form/SearchInput";
import PaginationComponent from "components/PaginationComponent";
import { tableColumns } from "helpers/table";
import { IBusiness } from "models/business";
import useBusiness from "hooks/useBusiness";
import { IUser } from "models/user";

export default function Business() {
  const {
    handlePageChange,
    handleSearch,
    handleSortChange,
    data: { loading, data, page, per_page, total },
  } = useBusiness();
  return (
    <div className="w-full">
      <div className="w-full rounded-lg bg-white px-4 py-5 flex flex-col gap-y-4">
        <div className="w-full flex flex-col sm:flex-row items-center justify-between gap-6">
          <h1 className="text-black text-2xl font-normal w-full whitespace-nowrap">
            Businesses
          </h1>
          <SearchInput
            onChange={handleSearch}
            placeholder="Search admin by name"
          />
        </div>
        <div className="w-full overflow-x-auto">
          <Table
            columns={columns()}
            dataSource={data}
            loading={loading}
            pagination={{ position: ["none"] }}
            onChange={handleSortChange}
          />
        </div>

        <PaginationComponent
          loading={loading}
          page={page}
          perPage={per_page}
          total={total}
          handlePageChange={handlePageChange}
        />
      </div>
    </div>
  );
}

function columns(): TableColumnsType<IBusiness> {
  return tableColumns<IBusiness>([
    { title: "Name", sorter: true },
    {
      title: "Owner",
      dataIndex: "user",
      render: (user?: IUser) => (
        <p className="text-[#303030] font-normal text-base">
          {user?.first_name} {user?.last_name}
        </p>
      ),
    },
    { title: "Email" },
    { title: "Category" },
    { title: "Description" },
    { title: "State" },
    { title: "Year", sorter: true },
    {
      title: "Image",
      render: (img?: string) => (
        <Avatar
          src={img}
          icon={<BankOutlined className="text-xl" />}
          alt="profile"
        />
      ),
    },
  ]);
}

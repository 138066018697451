import { useState } from "react";
import Sidebar from "./Sidebar";
import DashHeader from "./DashHeader";
import { Outlet } from "react-router-dom";

function DashBoard() {
  const [showSideBar, setShowSideBar] = useState<boolean>(false);

  const openSidebar = () => setShowSideBar(true);
  const toggleSidebar = () => setShowSideBar((prev) => !prev);
  return (
    <div className="h-full bg-boardColor relative">
      <Sidebar show={showSideBar} handleClose={toggleSidebar} />

      <div className="w-[calc(100%-80px)] sm:w-[calc(100%-250px)] left-20 sm:left-[250px] relative overflow-y-auto">
        <DashHeader openSidebar={openSidebar} />
        <div className="w-full h-full pt-2 pb-10 px-3 sm:px-6">
          <Outlet />
        </div>
      </div>
    </div>
  );
}

export default DashBoard;

import ArrowMoreUpIcon from "components/icons/ArrowMoreUpIcon";
import { Link } from "react-router-dom";
import { RouteName } from "routes";

export default function QuickLink() {
  return (
    <div className="w-full max-w-2xl flex flex-col gap-y-4">
      <h4 className="text-xl font-normal text-darker-gray">Quick Links</h4>
      <Link
        to={RouteName.Accounts}
        className="px-4 py-3 rounded-lg bg-white text-dark-gray font-normal text-base flex justify-between w-full items-center"
      >
        Accounts
        <ArrowMoreUpIcon />
      </Link>
      <Link
        to={RouteName.Users}
        className="px-4 py-3 rounded-lg bg-white text-dark-gray font-normal text-base flex justify-between w-full items-center"
      >
        Users
        <ArrowMoreUpIcon />
      </Link>
    </div>
  );
}

import { yupResolver } from "@hookform/resolvers/yup";
import { updateAccountApi } from "api/account";
import { useNotificationContext } from "contexts/NotificationContext";
import { IUserUpdateInput } from "models";
import { useState } from "react";
import { useForm, SubmitHandler } from "react-hook-form";
import userCreateValidator from "validator/userCreateValidator";

export default function useUpdateModal() {
  const [loading, setLoading] = useState(false);
  const [open, setOpen] = useState(false);
  const { showError, showSuccess } = useNotificationContext();
  const {
    control,
    handleSubmit,
    formState: { errors },
  } = useForm<IUserUpdateInput>({
    resolver: yupResolver(userCreateValidator),
    defaultValues: { type: "Admin" },
  });
  console.log(errors);

  const onSubmit: SubmitHandler<IUserUpdateInput> = async (id: any, input) => {
    const { error } = await updateAccountApi(id, input);
    if (error) showError(error.message);
    else {
      showSuccess("You have successfully updated a user");
      handleUpdateCancel();
    }
  };

  const showUpdateModal = () => {
    setOpen(true);
  };

  const handleUpdateOk = () => {
    setLoading(true);
    setTimeout(() => {
      setOpen(false);
      setLoading(false);
    }, 3000);
    handleSubmit(onSubmit);
  };

  const handleUpdateCancel = () => {
    setOpen(false);
  };
  return {
    setLoading,
    open,
    loading,
    setOpen,
    showUpdateModal,
    handleUpdateCancel,
    onSubmit,
    handleUpdateOk,
    handleSubmit,
    control,
  };
}

import { Pagination } from "antd";
import ArrowLeftIcon from "./icons/ArrowLeftIcon";
import ArrowRightIcon from "./icons/ArrowRightIcon";
import { useMemo } from "react";

interface IProps {
  total: number;
  perPage: number;
  page: number;
  handlePageChange: (page: number) => void;
  loading: boolean;
}

export default function PaginationComponent({
  handlePageChange,
  page,
  perPage,
  total,
  loading,
}: IProps) {
  const countStart = useMemo(
    () => (page - 1) * perPage + 1,
    // eslint-disable-next-line
    [page]
  );
  const countEnd = useMemo(() => {
    const count = (page - 1) * perPage + perPage;
    return count > total ? total : count;
    // eslint-disable-next-line
  }, [page]);

  return (
    <div className="w-full py-4 flex flex-col sm:flex-row items-center justify-between gap-y-6">
      <p className="text-base font-normal text-[#464646]">
        Items per page: {perPage}
      </p>
      <div className="flex gap-x-16 items-center">
        <button
          disabled={loading || page === 1}
          className="outline-none border-none hidden sm:flex items-center gap-x-1 text-dark-blue disable:text-[#A0A0A0] disabled:cursor-not-allowed hover:bg-gray-100 px-1 pr-2 py-1 rounded-md"
          onClick={() => handlePageChange(page - 1)}
        >
          <ArrowLeftIcon />
          <span className="text-base capitalize font-medium">prev</span>
        </button>
        <Pagination
          className="h-9 sm:h-auto"
          onChange={handlePageChange}
          total={total}
          defaultPageSize={perPage}
          hideOnSinglePage
          current={page}
          showSizeChanger={false}
          disabled={loading}
          itemRender={(_, type, originalElement) => {
            if (type === "prev" || type === "next") return <></>;
            return originalElement;
          }}
        />
        <button
          className="outline-none border-none hidden sm:flex items-center gap-x-1 text-dark-blue disable:text-[#A0A0A0] disabled:cursor-not-allowed hover:bg-gray-100 px-1 pr-2 py-1 rounded-md"
          disabled={loading || !(page < Math.ceil(total / perPage))}
          onClick={() => handlePageChange(page + 1)}
        >
          <ArrowRightIcon />
          <span className="text-base capitalize font-medium">next</span>
        </button>
      </div>
      <p className="text-base font-normal text-[#464646]">
        Results: {countStart}-{countEnd} of {total}
      </p>
    </div>
  );
}

import { Button, Modal } from "antd";
import EmailInput from "components/Form/EmailInput";
import { IUserUpdateInput } from "models";
import SelectInput from "components/Form/SelectInput";
import { Control } from "react-hook-form";

interface IProps {
  open: boolean;
  loading: boolean;
  handleUpdateCancel: () => void;
  handleUpdateOk: () => void;
  control: Control<IUserUpdateInput>;
  emailValue: string;
}

function UpdateModal({
  handleUpdateCancel,
  handleUpdateOk,
  loading,
  open,
  control,
  emailValue,
}: IProps): JSX.Element {
  return (
    <form>
      <Modal
        open={open}
        centered={true}
        title="update a User"
        onOk={handleUpdateOk}
        onCancel={handleUpdateCancel}
        footer={[
          <Button
            className="w-full flex justify-center items-center my-4 py-6 px-5 outline-none border bg-btnColor rounded-lg capitalize text-white font-medium text-center"
            key="submit"
            loading={loading}
            onClick={handleUpdateOk}
          >
            {loading ? "Submitting" : "Submit"}
          </Button>,
        ]}
      >
        <div className="w-full flex flex-col gap-y-3">
          <label
            htmlFor="email"
            className="capitalize text-textColor text-sm text-left"
          >
            email
          </label>
          <EmailInput control={control} name="email" placeholder={emailValue} />

          <SelectInput name="type" control={control} />
        </div>
      </Modal>
    </form>
  );
}

export default UpdateModal;

import { userCashOutTransactionsApi } from "api/transaction";
import { useNotificationContext } from "contexts/NotificationContext";
import { appendKey } from "helpers/table";
import { IUserTransaction, userTransactionDefault } from "models/transaction";
import { ChangeEvent, useEffect, useState } from "react";
import { useParams } from "react-router-dom";

export default function useUserCashOut() {
  const id: string = useParams().id!;
  const { showError } = useNotificationContext();
  const [data, setData] = useState<IUserTransaction>(userTransactionDefault);
  const [search, setSearch] = useState<string>("");

  useEffect(() => {
    setData((prev) => ({ ...prev, loading: true }));
    userCashOutTransactionsApi(
      id,
      data.data.page,
      data.data.per_page,
      search
    ).then(({ data, error }) => {
      if (data) {
        const owner = `${data.user?.first_name} ${data.user?.last_name}`;
        data.data.data = data.data.data.map((value) => ({ ...value, owner }));
        data.data.data = appendKey(data.data.data);
        setData({ ...data, loading: false });
      } else showError(error!.message);
    });
    // eslint-disable-next-line
  }, [data.data.page, search]);

  const handlePageChange = (page: number) =>
    setData((prev) => {
      const temp = { ...prev, loading: true };
      temp.data.page = page;
      return temp;
    });

  const handleSearch = ({
    target: { value },
  }: ChangeEvent<HTMLInputElement>) => {
    setSearch(value);
  };

  return { data, handlePageChange, handleSearch };
}

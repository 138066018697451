import { Avatar, Table, TableColumnsType } from "antd";
import SearchInput from "components/Form/SearchInput";
import { tableColumns } from "helpers/table";
import { ChangeEvent } from "react";
import useCountry from "hooks/useCountry";
import { ICountry } from "models/country";
import EditIcon from "components/icons/EditIcon";
import CreateModal from "./CreateModal";
import UpdateModal from "./UpdateModal";
import DeleteButton from "./DeleteButton";

export default function Country() {
  const {
    data: { data, loading },
    handleCloseCreateModal,
    handleOpenCreateModal,
    openCreateModal,
    reloadData,
    handleCloseUpdateModal,
    handleOpenUpdateModal,
    openUpdateModal,
  } = useCountry();

  return (
    <div className="w-full">
      <div className="w-full rounded-lg bg-white px-4 py-5 flex flex-col gap-y-4">
        <div className="w-full flex flex-col sm:flex-row items-center justify-between gap-6">
          <h1 className="text-black text-2xl font-normal w-full whitespace-nowrap">
            All Countries
          </h1>
          <SearchInput
            onChange={({
              target: { value },
            }: ChangeEvent<HTMLInputElement>) => {
              console.log(value);
            }}
            placeholder="Search country"
          />

          <button
            onClick={handleOpenCreateModal}
            className="hover:bg-btnColor transition-all duration-500 hover:text-white outline-none text-base font-semibold text-black whitespace-nowrap px-4 py-2 border-btnColor hover:border-dark-blue border-2 rounded-md w-full sm:w-fit"
          >
            Create Country
          </button>
        </div>

        <div className="w-full overflow-x-auto">
          <Table
            columns={columns(handleOpenUpdateModal, reloadData)}
            dataSource={data}
            loading={loading}
            pagination={{ position: ["none"] }}
          />
        </div>
        {openCreateModal && (
          <CreateModal
            handleClose={handleCloseCreateModal}
            open={openCreateModal}
            reloadData={reloadData}
          />
        )}
        {openUpdateModal.country && openUpdateModal.open && (
          <UpdateModal
            handleClose={handleCloseUpdateModal}
            open={openUpdateModal.open}
            reloadData={reloadData}
            country={openUpdateModal.country}
          />
        )}
      </div>
    </div>
  );
}

function columns(
  handleOpenUpdateModal: (value: ICountry) => void,
  reloadData: () => void
): TableColumnsType<ICountry> {
  return tableColumns<ICountry>([
    { title: "Name" },
    { title: "Short Name", dataIndex: "short_name" },
    { title: "Code" },
    {
      title: "Flag",
      render: (flag: string) => (
        <Avatar src={flag} shape="square" className="w-10 h-7" />
      ),
    },
    {
      title: "Action",
      key: "action",
      align: "center",
      render: (_, country: ICountry) => (
        <div className="w-full flex items-center justify-center">
          <button
            onClick={() => handleOpenUpdateModal(country)}
            className="hover:bg-gray-200 transition-all duration-500 text-btnColor outline-none text-xl w-8 h-8 flex items-center justify-center rounded-full"
          >
            <EditIcon />
          </button>
          <DeleteButton country={country} reloadData={reloadData} />
        </div>
      ),
    },
  ]);
}

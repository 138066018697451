import { authorizeGetRequest, authorizePutRequest } from "api";
import { IConfig, IConfigInput } from "models/config";

export const findConfigApi = () => {
  return authorizeGetRequest<IConfig>(`configs/version`);
};

export const updateConfigVersionApi = (data: IConfigInput) => {
  return authorizePutRequest<IConfig>(`configs/version`, data);
};

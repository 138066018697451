import { Dropdown } from "antd";
import ArrowDownIcon from "./icons/ArrowDownIcon";
import { useMemo, useState } from "react";
import { IDashboardTransactionType } from "models/dashboard";

interface IProps {
  handleFilterChange: (value: IDashboardTransactionType) => void;
}

export default function TransactionTypeFilterButton({
  handleFilterChange,
}: IProps) {
  const [value, setValue] = useState<IDashboardTransactionType>("cash_in");
  const handleOnClick = (value: IDashboardTransactionType) => {
    setValue(value);
    handleFilterChange(value);
  };
  const items = useMemo(() => {
    const items = [
      {
        key: "0",
        onClick: () => handleOnClick("cash_in"),
        label: <Label text={"Cash In"} />,
      },
      {
        key: "1",
        onClick: () => handleOnClick("cash_out"),
        label: <Label text="Cash Out" />,
      },
    ];
    return items;
    // eslint-disable-next-line
  }, []);

  return (
    <Dropdown menu={{ items }}>
      <button className="outline-none border-none text-blue text-sm flex gap-2 items-center capitalize">
        {value.replace("_", " ")}
        <ArrowDownIcon />
      </button>
    </Dropdown>
  );
}

function Label({ text }: { text: string }) {
  return (
    <span className="text-dark-gray text-sm py-1 px-1 font-medium capitalize">
      {text}
    </span>
  );
}

import React from "react";

function Status404() {
  return (
    <div className="w-full h-full flex flex-col my-auto items-center justify-center">
      <h1 className=" text-7xl text-red-800 font-bold">page not found</h1>
      <h1 className=" text-7xl text-red-800 font-bold">404</h1>
    </div>
  );
}

export default Status404;

import { DashboardNewUserFilterButton } from "components/DashboardFilterButton";
import { IDashboardNewUserFilter, IUserChart } from "models/dashboard";
import CountUp from "react-countup";
import {
  BarChart,
  CartesianGrid,
  XAxis,
  YAxis,
  Bar,
  Tooltip,
  Rectangle,
  ResponsiveContainer,
} from "recharts";

interface IProps {
  data: IUserChart[];
  handleFilterChange: (type: IDashboardNewUserFilter) => Promise<void>;
}

export default function TotalTransactionChart({
  data,
  handleFilterChange,
}: IProps) {
  return (
    <div className="w-full bg-white rounded-lg p-4">
      <div className="w-full flex items-center gap-5 justify-between">
        <h6 className="text-darker-gray text-lg">Total Transaction</h6>
        <DashboardNewUserFilterButton
          handleFilterChange={handleFilterChange}
          addDaily
        />
      </div>
      <div className="w-full mt-4">
        <p className="font-semibold text-xl text-dark-blue">
          <CountUp end={data.reduce((prev, curr) => prev + curr.user, 0)} />
        </p>
        <div className="w-full mt-10">
          <ResponsiveContainer width="100%" height={227}>
            <BarChart
              data={data}
              margin={{
                top: 4,
                right: 4,
                left: 4,
                bottom: 4,
              }}
            >
              <CartesianGrid strokeDasharray="3 3" />
              <XAxis dataKey="name" />
              <YAxis />
              <Tooltip />
              <Bar
                dataKey="user"
                fill="#0000A199"
                width={9}
                strokeWidth={9}
                barSize={9}
                stroke="9px"
                activeBar={<Rectangle fill="#0000A1" stroke="#0000A1" />}
              />
            </BarChart>
          </ResponsiveContainer>
        </div>
      </div>
    </div>
  );
}

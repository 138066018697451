import {
  authorizeDeleteRequest,
  authorizeGetRequest,
  authorizePatchRequest,
  authorizePostRequest,
} from "api";
import {
  ICountry,
  ICreateCountry,
  ICountryResponse,
  IUpdateCountry,
} from "models/country";

export const countriesApi = () => {
  return authorizeGetRequest<ICountryResponse>("countries");
};

export const createCountryApi = (data: ICreateCountry) => {
  const formData = new FormData();
  formData.append("flag", data.flag.file);
  delete data.flag;
  formData.append("data", JSON.stringify(data));
  return authorizePostRequest<ICountry>("countries", formData);
};

export const updateCountryApi = (id: string, data: IUpdateCountry) => {
  const formData = new FormData();
  if (data.flag) formData.append("flag", data.flag.file);
  delete data.flag;
  formData.append("data", JSON.stringify(data));
  return authorizePatchRequest<ICountry>(`countries/${id}`, formData);
};

export const deleteCountryApi = (id: string) => {
  return authorizeDeleteRequest<ICountry>(`countries/${id}`);
};

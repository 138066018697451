import CountUp from "react-countup";
import { Link } from "react-router-dom";

interface IProps {
  title: string;
  count: number;
  to: string;
}

export default function Card({ count, title, to }: IProps) {
  return (
    <Link
      to={to}
      className="flex flex-col p-3 bg-white max-w-56 rounded gap-y-2 min-h-32 w-full shadow-md hover:bg-slate-50 transition-all duration-300 hover:shadow-xl hover:scale-105"
    >
      <p className="text-sm font-normal text-dark-gray whitespace-nowrap">
        {title}
      </p>
      <h3 className="text-darker-gray font-semibold text-xl">
        <CountUp end={count} />
      </h3>
    </Link>
  );
}

import * as yup from "yup";

export const userCreateValidator = yup
  .object({
    email: yup
      .string()
      .required("Email is required")
      .email("Enter a valid email"),
    type: yup.string().required("Account type is required"),
  })
  .required();

export default userCreateValidator;

import { Dropdown, TableColumnsType } from "antd";
import MoreIcon from "components/icons/MoreIcon";

interface ITableHeaderProp {
  title: string;
  dataIndex?: string;
  key?: string;
  align?:
    | "center"
    | "right"
    | "left"
    | "start"
    | "end"
    | "match-parent"
    | "justify";
  render?: (text: any, value: any) => JSX.Element;
  sorter?: boolean;
}

interface IMenuProp {
  label: string;
  onClick: () => void;
}

export function tableColumns<T>(
  data: ITableHeaderProp[],
  actions?: (value: T) => IMenuProp[]
): TableColumnsType<T> {
  const columns: TableColumnsType<T> = data.map(
    ({ title, align, dataIndex, key, render, sorter }) => ({
      className: "whitespace-nowrap",
      title: title,
      align: align,
      sorter: sorter,
      onFilterDropdownOpenChange: () => console.log(title),
      dataIndex: dataIndex ?? title.toLowerCase().replace(" ", "_"),
      key: key ?? title.toLowerCase(),
      render: render
        ? render
        : (text) => (
            <p className="text-[#303030] font-normal text-base">
              {text ?? "-"}
            </p>
          ),
    })
  );
  if (actions?.length)
    columns.push({
      title: "Action",
      key: "action",
      render: (value) => {
        return (
          <Dropdown
            trigger={["click"]}
            menu={{
              items: actions(value).map(({ label, onClick }, key) => ({
                key,
                onClick,
                label: (
                  <span className="text-dark-gray font-medium text-sm py-1 px-3">
                    {label}
                  </span>
                ),
              })),
            }}
          >
            <button className="px-2 py-2px">
              <MoreIcon />
            </button>
          </Dropdown>
        );
      },
      width: 80,
      align: "center",
    });
  return columns;
}

export function appendKey<T>(data: T[]): T[] {
  return data.map((value, key) => ({ ...value, key }));
}

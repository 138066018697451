import { DashboardNewUserFilterButton } from "components/DashboardFilterButton";
import {
  IDashboardNewUserFilter,
  IDashboardTransaction,
} from "models/dashboard";
import { useMemo } from "react";
import { PieChart, Pie, Cell, ResponsiveContainer } from "recharts";

interface IProps {
  handleFilterChange: (type: IDashboardNewUserFilter) => Promise<void>;
  data: IDashboardTransaction[];
  handleActiveChartChange: (index: number) => void;
}

export default function TransactionOverview({
  handleFilterChange,
  handleActiveChartChange,
  data,
}: IProps) {
  const COLORS = ["#F2706F", "#00AF8C", "#F0C168"];
  const total = useMemo(() => {
    const total = data.reduce((prev, curr) => curr.value + prev, 0);
    return total <= 0 ? 1 : total;
    // eslint-disable-next-line
  }, [data]);

  return (
    <div className="w-full bg-white rounded-lg p-4 pr-0">
      <div className="w-full flex items-center gap-5 justify-between">
        <h6 className="text-darker-gray text-lg">Transaction Overview</h6>
        <DashboardNewUserFilterButton
          handleFilterChange={handleFilterChange}
          addDaily
        />
      </div>
      <div className="w-full mt-10 flex flex-col sm:flex-row items-center gap-5">
        <div className="w-full flex justify-center">
          <ResponsiveContainer width={270} height={270}>
            <PieChart>
              <Pie
                data={data}
                cx={120}
                cy={120}
                innerRadius={80}
                outerRadius={120}
                fill="#8884d8"
                paddingAngle={0}
                dataKey="value"
              >
                {data.map((entry, index) => {
                  return (
                    <Cell
                      onMouseEnter={() => handleActiveChartChange(index)}
                      key={`cell-${index}`}
                      fill={COLORS[index % COLORS.length]}
                      strokeWidth={entry.active ? 0 : 20}
                    />
                  );
                })}
              </Pie>
            </PieChart>
          </ResponsiveContainer>
        </div>
        <div className="w-full max-w-sm flex flex-col gap-y-6">
          {data.map((value, key) => (
            <div className="w-full flex gap-x-4" key={key}>
              <div className="w-full flex flex-col gap-y-2">
                <div className="w-full flex items-center justify-between">
                  <p className="text-sm font-normal text-darker-gray">
                    {value.name}
                  </p>
                  <p
                    className={`text-sm font-normal ${
                      key === 0
                        ? "text-red"
                        : key === 1
                        ? "text-green"
                        : "text-yellow"
                    }`}
                  >
                    {Math.round((value.value * 100) / (total ?? 1))}%
                  </p>
                </div>
                <div
                  className={`w-full h-2px ${
                    key === 0 ? "bg-red" : key === 1 ? "bg-green" : "bg-yellow"
                  } rounded`}
                ></div>
              </div>
              <div
                className={`w-2px ${
                  value.active ? "opacity-100" : "opacity-0"
                } rounded ${
                  key === 0 ? "bg-red" : key === 1 ? "bg-green" : "bg-yellow"
                }`}
              ></div>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
}

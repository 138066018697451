import { DashboardNewUserFilterButton } from "components/DashboardFilterButton";
import { IDashboardNewUserFilter } from "models/dashboard";
import CountUp from "react-countup";

interface IProps {
  count: number;
  handleFilterChange: (type: IDashboardNewUserFilter) => Promise<void>;
}
export default function ActiveUserCard({ count, handleFilterChange }: IProps) {
  return (
    <div className="flex flex-col p-4 bg-white min-w-52 max-w-72 rounded-lg gap-y-5 w-full shadow-md">
      <div className="flex flex-col gap-y-2">
        <p className="text-base font-normal text-dark-gray whitespace-nowrap">
          Active Users
        </p>
        <h3 className="text-darker-gray font-semibold text-2xl">
          <CountUp end={count} />
        </h3>
      </div>
      <DashboardNewUserFilterButton
        handleFilterChange={handleFilterChange}
        addDaily
      />
    </div>
  );
}

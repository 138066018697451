import { Table, TableColumnsType } from "antd";
import SearchInput from "components/Form/SearchInput";
import PaginationComponent from "components/PaginationComponent";
import { tableColumns } from "helpers/table";
import useUser from "hooks/useUser";
import { IUser } from "models/user";
import moment from "moment";

export default function User() {
  const {
    handlePageChange,
    handleRowClick,
    users,
    handleSearch,
    handleSortChange,
  } = useUser();

  return (
    <div className="w-full">
      {" "}
      <div className="w-full rounded-lg bg-white px-4 py-5 flex flex-col gap-y-4">
        <div className="w-full flex flex-col sm:flex-row sm:items-center justify-between gap-5">
          <h1 className="text-black text-2xl font-normal whitespace-nowrap">
            All Users
          </h1>

          <SearchInput
            onChange={handleSearch}
            placeholder="Search users by first name, last name, phone"
          />
        </div>
        <div className="w-full overflow-x-auto">
          <Table
            rowClassName="hover:cursor-pointer"
            columns={columns}
            onRow={handleRowClick}
            dataSource={users.data}
            loading={users.loading}
            pagination={{ position: ["none"] }}
            onChange={handleSortChange}
          />
        </div>
        <PaginationComponent
          loading={users.loading}
          page={users.page}
          perPage={users.per_page}
          total={users.total}
          handlePageChange={handlePageChange}
        />
      </div>
    </div>
  );
}

const columns: TableColumnsType<IUser> = tableColumns<IUser>(
  [
    {
      title: "First Name",
      dataIndex: "first_name",
      sorter: true,
    },
    {
      title: "Last Name",
      dataIndex: "last_name",
      sorter: true,
    },
    {
      title: "Email",
      sorter: true,
    },
    {
      title: "Phone",
      sorter: true,
    },

    {
      title: "Join At",
      dataIndex: "created_at",
      render: (createdAt: string) => <p>{moment(createdAt).format("LL")}</p>,
      sorter: true,
    },
  ],
  (user) => {
    const menus = [];
    if (user.is_active) menus.push({ label: "Deactivate", onClick: () => {} });
    else menus.push({ label: "Activate", onClick: () => {} });
    return menus;
  }
);

export interface IError {
  status: number;
  message: string;
  errors: string[];
}

export interface IApiResponse<T> {
  data?: T;
  error?: IError;
}

export * from "./account";
export * from "./auth";

export enum OrderType {
  ascend = "ascend",
  descend = "descend",
}

export interface IAccount {
  key: string;
  id: string;
  email: string;
  type: "Admin" | "SuperAdmin";
  is_active: boolean;
  created_at: string;
  updated_at: string;
}

export interface IAccountResponse {
  per_page: number;
  page: number;
  total: number;
  data: IAccount[];
}

export interface IAccountData extends IAccountResponse {
  loading: boolean;
}

export enum AccountSortType {
  email = "email",
  type = "type",
  is_active = "is_active",
  created_at = "created_at",
}

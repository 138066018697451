import { IUser } from "./user";

export interface ITransaction {
  id: string;
  user_id: string;
  business_id: string;
  created_at: string;
  updated_at: string;
  is_expense: boolean;
  payment_method: string;
  description: string;
  category?: string;
  total: number;
  paid: number;
  discount: {
    amount: number;
    type: string;
  };
  date: string;
  person_id: null;
  debt_repayment_date?: string;
  items: ITransactionItem[];
  payment_history: ITransactionPaymentHistory[];
}

export interface ITransactionItem {
  id: string;
  created_at: string;
  updated_at: string;
  transaction_id: string;
  is_product: boolean;
  name: string;
  amount: number;
  unit?: string;
  quantity: number;
}

export interface ITransactionPaymentHistory {
  id: string;
  created_at: string;
  updated_at: string;
  transaction_id: string;
  amount: number;
  date: string;
}

export interface IUserTransaction extends IUserTransactionResponse {
  loading: boolean;
}

export const userTransactionDefault: IUserTransaction = {
  data: { data: [], page: 1, per_page: 10, total: 0 },
  loading: true,
};

export interface IUserTransactionResponse {
  data: ITransactionResponse;
  user?: IUser;
}

export interface ITransactionResponse {
  per_page: number;
  page: number;
  total: number;
  data: ITransaction[];
}

export interface ITransactions extends ITransactionResponse {
  loading: boolean;
}

export const userTransactionsDefault: ITransactions = {
  data: [],
  page: 1,
  per_page: 10,
  total: 0,
  loading: true,
};

export const transactionsDefault: ITransactions = {
  data: [],
  page: 1,
  per_page: 10,
  total: 0,
  loading: true,
};

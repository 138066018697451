import Card from "./components/Card";
import TransactionOverview from "./components/TransactionOverview";
import CustomerSupplierOverview from "./components/CustomerSupplierOverview";
import UserInfo from "./components/UserInfo";
import { RouteName } from "routes";
import useUserDetail from "hooks/useUserDetail";

export default function UserDetail() {
  const {
    data: {
      user,
      total_business,
      total_cash_in,
      total_cash_out,
      total_customer,
      total_debt,
      total_inventory,
      total_supplier,
      transaction,
    },
    id,
  } = useUserDetail();

  return (
    <div className="w-full">
      <UserInfo data={user} />
      <div className="mt-6 flex items-center justify-center sm:justify-start sm:items-start gap-6 flex-wrap">
        <Card
          to={RouteName.UserBusiness.replace(":id", id)}
          count={total_business}
          title="Total Businesses"
        />
        <Card
          to={RouteName.UserCashIn.replace(":id", id)}
          count={total_cash_in}
          title="Total Cash In"
        />
        <Card
          to={RouteName.UserCashOut.replace(":id", id)}
          count={total_cash_out}
          title="Total Cash Out"
        />
        <Card
          to={RouteName.UserDebt.replace(":id", id)}
          count={total_debt}
          title="Total Debt"
        />
        <Card
          to={RouteName.UserInventory.replace(":id", id)}
          count={total_inventory}
          title="Total Inventory"
        />
        <Card
          to={RouteName.UserCustomer.replace(":id", id)}
          count={total_customer}
          title="Total Customer"
        />
        <Card
          to={RouteName.UserSuppliers.replace(":id", id)}
          count={total_supplier}
          title="Total Supplier"
        />
      </div>
      <div className="w-full flex flex-col sm:flex-row gap-6 mt-10">
        <TransactionOverview data={transaction} />
        <CustomerSupplierOverview
          totalCustomer={total_customer}
          totalSupplier={total_supplier}
        />
      </div>
    </div>
  );
}

import { IDashboardNewUserFilter, IUserChart } from "models/dashboard";
import moment from "moment";

export class ChartHelper {
  static userChartFromDateValueMap(
    data: {
      [key: string]: number;
    },
    type: IDashboardNewUserFilter
  ): IUserChart[] {
    const userChart: IUserChart[] = [];
    const daily: IDashboardNewUserFilter[] = ["today", "yesterday"];
    if (daily.includes(type))
      for (let key in data) {
        userChart.push({
          name: moment(key, "YYYY-MM-DD HH:mm").format("HH:mm"),
          user: data[key],
        });
      }
    else
      for (let key in data) {
        userChart.push({
          name: moment(key, "YYYY-MM-DD").format("MMM DD"),
          user: data[key],
        });
      }
    return userChart;
  }

  static percentFromDateValueMap(
    data: { [key: string]: number },
    total: number
  ): number {
    const sum = Object.values(data).reduce((prev, curr) => prev + curr, 0);
    return (sum * 100) / total;
  }
}

import CountUp from "react-countup";
import {
  IDashboardCountWithPercent,
  IDashboardNewUserFilter,
  IDashboardTransactionType,
} from "models/dashboard";
import { DashboardNewUserFilterButton } from "components/DashboardFilterButton";
import TransactionTypeFilterButton from "components/TransactionTypeFilterButton";
import { useEffect, useRef, useState } from "react";

interface IProps {
  data: IDashboardCountWithPercent;
  handleTypeChange: (
    transactionType: IDashboardTransactionType,
    type: IDashboardNewUserFilter
  ) => Promise<void>;
}

export default function TotalTransactionCard({
  data,
  handleTypeChange,
}: IProps) {
  const [type, setType] = useState<{
    transactionType: IDashboardTransactionType;
    type: IDashboardNewUserFilter;
  }>({ transactionType: "cash_in", type: "today" });
  const didMount = useRef<boolean>(false);

  useEffect(() => {
    if (didMount.current)
      handleTypeChange(type.transactionType, type.type).then(() => {});
    else didMount.current = true;
    // eslint-disable-next-line
  }, [type]);

  return (
    <div className="flex flex-col p-4 bg-white min-w-52 max-w-72 rounded-lg gap-y-5 w-full shadow-md">
      <div className="flex flex-col gap-y-2">
        <div className="flex items-center justify-between gap-x-10">
          <p className="text-base font-normal text-dark-gray whitespace-nowrap">
            Total Transaction
          </p>
          <TransactionTypeFilterButton
            handleFilterChange={(transactionType) =>
              setType((prev) => ({ ...prev, transactionType }))
            }
          />
        </div>
        <h3 className="text-darker-gray font-semibold text-2xl">
          <CountUp end={data.count} />
        </h3>
      </div>
      <div className="flex items-center gap-x-28 justify-between">
        <DashboardNewUserFilterButton
          handleFilterChange={(type) => setType((prev) => ({ ...prev, type }))}
          addDaily
          showAll
        />
        <p className="text-sm font-normal text-blue">
          <CountUp end={data.percent} />%
        </p>
      </div>
    </div>
  );
}

import { businessesApi } from "api/business";
import { useNotificationContext } from "contexts/NotificationContext";
import { OrderType } from "models";
import {
  BusinessSortType,
  IBusinesses,
  businessesDefault,
} from "models/business";
import { ChangeEvent, useEffect, useState } from "react";

export default function useBusiness() {
  const { showError } = useNotificationContext();
  const [business, setBusiness] = useState<IBusinesses>(businessesDefault);
  const [search, setSearch] = useState<string>("");

  useEffect(() => {
    fetchData(BusinessSortType.created_at, OrderType.descend);
    // eslint-disable-next-line
  }, [business.page, search]);

  const fetchData = async (sort: BusinessSortType, order: OrderType) => {
    setBusiness((prev) => ({ ...prev, loading: true }));
    const { data, error } = await businessesApi(
      business.page,
      business.per_page,
      search,
      sort,
      order
    );
    if (data) {
      setBusiness({ ...data, loading: false });
    } else showError(error!.message);
  };

  const handlePageChange = (page: number) =>
    setBusiness((prev) => {
      const temp = { ...prev, loading: true };
      temp.page = page;
      return temp;
    });

  const handleSearch = ({
    target: { value },
  }: ChangeEvent<HTMLInputElement>) => {
    setSearch(value);
  };

  const handleSortChange = async (_: any, __: any, { field, order }: any) => {
    if (order) await fetchData(field, order);
  };

  return { data: business, handlePageChange, handleSearch, handleSortChange };
}

import { authorizeGetRequest } from "api";
import { IDebtResponse, IUserDebtResponse } from "models/debt";

export const userDebtsApi = (
  id: string,
  page: number,
  perPage: number,
  search: string
) => {
  return authorizeGetRequest<IUserDebtResponse>(
    `debts/user/${id}?page=${page}&per_page=${perPage}&query=${search}`
  );
};

export const debtsApi = (page: number, perPage: number, search: string) => {
  return authorizeGetRequest<IDebtResponse>(
    `debts?page=${page}&per_page=${perPage}&query=${search}`
  );
};

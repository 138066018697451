import * as yup from "yup";

const createCountryValidator = yup
  .object({
    name: yup.string().required("Name is required"),
    short_name: yup
      .string()
      .required("Short name is required")
      .length(2, "Short name must be 2 characters"),
    code: yup
      .string()
      .required("Code is required")
      .test("digit-only", "Code must be country code. e.g +251", (val) =>
        /^\+\d+$/.test(val)
      ),
    flag: yup
      .mixed()
      .required("Flag is required")
      .test("file-required", "Flag is required", (value) => !!value),
  })
  .required();

export default createCountryValidator;

export const updateCountryValidator = yup
  .object({
    name: yup.string().required("Name is required"),
    short_name: yup
      .string()
      .required("Short name is required")
      .length(2, "Short name must be 2 characters"),
    code: yup
      .string()
      .required("Code is required")
      .test("digit-only", "Code must be country code. e.g +251", (val) =>
        /^\+\d+$/.test(val)
      ),
    flag: yup.mixed(),
  })
  .required();

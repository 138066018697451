import NewUserCard from "./components/NewUserCard";
import TotalUserCard from "./components/TotalUserCard";
import TotalTransactionCard from "./components/TotalTransactionCard";
import TotalPersonCard from "./components/TotalPersonCard";
import QuickLink from "./components/QuickLink";
import UserRegistrationOverview from "./components/UserRegistrationOverview";
import TotalTransactionChart from "./components/TotalTransactionChart";
import TransactionOverview from "./components/TransactionOverview";
import useDashboard from "hooks/useDashboard";
import ActiveUserCard from "./components/ActiveUserCard";

export default function Dashboard() {
  const {
    data,
    handleTransactionActiveChange,
    handlePersonTypeChange,
    handleTransactionTypeChange,
    handleTransactionChange,
    handleUserRegisterTypeChange,
    handleNewUserChange,
    handleActiveUserChange,
    handleTransactionChartTypeChange,
  } = useDashboard();

  return (
    <div className="w-full">
      <div className="flex items-center justify-center sm:justify-start sm:items-start gap-6 flex-wrap">
        <NewUserCard
          count={data.newUser}
          handleFilterChange={handleNewUserChange}
        />
        <ActiveUserCard
          count={data.activeUsers}
          handleFilterChange={handleActiveUserChange}
        />
        <TotalUserCard count={data.totalUsers} />
        <TotalTransactionCard
          handleTypeChange={handleTransactionTypeChange}
          data={data.transactionCard}
        />
        <TotalPersonCard
          handleTypeChange={handlePersonTypeChange}
          data={data.personCard}
        />
      </div>
      <div className="w-full flex flex-col sm:flex-row items-start gap-6 mt-9">
        <UserRegistrationOverview
          data={data.userGraph}
          handleFilterChange={handleUserRegisterTypeChange}
        />
        <QuickLink />
      </div>
      <div className="w-full flex flex-col sm:flex-row items-start gap-6 mt-9">
        <TotalTransactionChart
          data={data.transactionChart}
          handleFilterChange={handleTransactionChartTypeChange}
        />
        <TransactionOverview
          data={data.transactions}
          handleActiveChartChange={handleTransactionActiveChange}
          handleFilterChange={handleTransactionChange}
        />
      </div>
    </div>
  );
}

import { authorizeGetRequest } from "api";
import { IInventoryResponse, IUserInventoryResponse } from "models/inventory";

export const userInventoriesApi = (
  id: string,
  page: number,
  perPage: number,
  search: string
) => {
  return authorizeGetRequest<IUserInventoryResponse>(
    `inventories/user/${id}?page=${page}&per_page=${perPage}&query=${search}`
  );
};

export const inventoriesApi = (
  page: number,
  perPage: number,
  search: string
) => {
  return authorizeGetRequest<IInventoryResponse>(
    `inventories?page=${page}&per_page=${perPage}&query=${search}`
  );
};

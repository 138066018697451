import { inventoriesApi } from "api/inventory";
import { useNotificationContext } from "contexts/NotificationContext";
import { IInventories, inventoriesDefault } from "models/inventory";
import { ChangeEvent, useEffect, useState } from "react";

export default function useInventory() {
  const { showError } = useNotificationContext();
  const [data, setData] = useState<IInventories>(inventoriesDefault);
  const [search, setSearch] = useState<string>("");

  useEffect(() => {
    inventoriesApi(data.page, data.per_page, search).then(({ data, error }) => {
      if (data) {
        setData({ ...data, loading: false });
      } else showError(error!.message);
    });
    // eslint-disable-next-line
  }, [data.page, search]);

  const handlePageChange = (page: number) =>
    setData((prev) => {
      const temp = { ...prev, loading: true };
      temp.page = page;
      return temp;
    });

  const handleSearch = ({
    target: { value },
  }: ChangeEvent<HTMLInputElement>) => {
    setSearch(value);
  };

  return { data, handlePageChange, handleSearch };
}

import { sendNotificationApi } from "api/notification";
import AppConfig from "./AppConfig";
import { useState } from "react";
import { useNotificationContext } from "contexts/NotificationContext";

function Notification() {
  const [input, setInput] = useState<{
    title: string;
    content: string;
  }>({ content: "", title: "" });
  const { showError, showSuccess } = useNotificationContext();
  const [loading, setLoading] = useState<boolean>(false);
  const handleSendNotification = async () => {
    if (input.content.trim().length === 0) {
      showError("Content is required");
      return;
    } else if (input.title.trim().length === 0) {
      showError("Title is required");
      return;
    }
    setLoading(true);
    const { error } = await sendNotificationApi(input);
    if (error) showError(error.message);
    else showSuccess("Notification sent successfully");
    setLoading(false);
  };

  return (
    <div className="flex flex-row gap-x-12">
      <div className="w-full h-auto bg-white rounded-lg px-8 py-5 flex flex-col gap-y-4">
        <h1 className="text-3xl font-bold text-center capitalize">
          send Notification
        </h1>

        <label
          htmlFor="title"
          className="capitalize text-textColor text-sm text-left"
        >
          title
        </label>
        <input
          onChange={({ target: { value } }) => {
            setInput({ ...input, title: value });
          }}
          value={input.title}
          type="text"
          name="title"
          placeholder="Title"
          className="w-full outline-none bg-white px-4 py-2 text-lg rounded-lg border border-borderColor"
        />
        <label
          htmlFor="content"
          className="capitalize text-textColor text-sm text-left"
        >
          Content
        </label>
        <textarea
          onChange={({ target: { value } }) => {
            setInput({ ...input, content: value });
          }}
          value={input.content}
          name="content"
          placeholder="content"
          className="w-full outline-none bg-white px-4 py-2 text-lg rounded-lg border border-borderColor"
        ></textarea>
        <button
          onClick={handleSendNotification}
          disabled={loading}
          className="w-full py-3 px-5 outline-none border disabled:opacity-50 bg-btnColor rounded-xl capitalize text-white font-medium text-center"
        >
          send
        </button>
      </div>
      <AppConfig />
    </div>
  );
}

export default Notification;

import { authorizeGetRequest } from "api";
import { IPersonResponse, IUserPersonResponse } from "models/person";

export const userCustomerApi = (
  id: string,
  page: number,
  perPage: number,
  search: string
) => {
  return authorizeGetRequest<IUserPersonResponse>(
    `people/user/${id}/customer?page=${page}&per_page=${perPage}&query=${search}`
  );
};

export const userSupplierApi = (
  id: string,
  page: number,
  perPage: number,
  search: string
) => {
  return authorizeGetRequest<IUserPersonResponse>(
    `people/user/${id}/supplier?page=${page}&per_page=${perPage}&query=${search}`
  );
};

export const peopleApi = (page: number, perPage: number, search: string) => {
  return authorizeGetRequest<IPersonResponse>(
    `people?page=${page}&per_page=${perPage}&query=${search}`
  );
};

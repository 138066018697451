export default function StatusBadge({ isActive }: { isActive: boolean }) {
  return (
    <div
      className={`px-4 py-1 flex items-center ${
        isActive ? "bg-green-100" : "bg-red-100"
      } rounded-2xl w-fit`}
    >
      <span
        className={`${
          isActive ? "text-green-600" : "text-red-600"
        } text-xs font-semibold`}
      >
        Active{isActive}
      </span>
    </div>
  );
}

import { Table, TableColumnsType } from "antd";
import SearchInput from "components/Form/SearchInput";
import PaginationComponent from "components/PaginationComponent";
import { tableColumns } from "helpers/table";
import moment from "moment";
import useUserInventory from "hooks/useUserInventory";
import { IInventory } from "models/inventory";

export default function UserInventory() {
  const {
    handlePageChange,
    handleSearch,
    data: {
      loading,
      user,
      data: { data, page, per_page, total },
    },
  } = useUserInventory();
  return (
    <div className="w-full">
      <div className="w-full rounded-lg bg-white px-4 py-5 flex flex-col gap-y-4">
        <div className="w-full flex flex-col sm:flex-row items-center justify-between gap-6">
          <h1 className="text-black text-2xl font-normal w-full whitespace-nowrap">
            {user?.first_name} {user?.last_name} Inventory
          </h1>
          <SearchInput onChange={handleSearch} placeholder="Search Cash Out" />
        </div>
        <div className="w-full overflow-x-auto">
          <Table
            columns={columns()}
            dataSource={data}
            loading={loading}
            pagination={{ position: ["none"] }}
          />
        </div>

        <PaginationComponent
          loading={loading}
          page={page}
          perPage={per_page}
          total={total}
          handlePageChange={handlePageChange}
        />
      </div>
    </div>
  );
}

function columns(): TableColumnsType<IInventory> {
  return tableColumns<IInventory>([
    { title: "Owner" },
    { title: "Name" },
    { title: "Cost" },
    { title: "Selling" },
    { title: "Quantity" },
    {
      title: "Created At",
      render: (date: string) => (
        <p className="text-[#303030] font-normal text-base">
          {moment(date).format("MMM DD, YYYY") ?? "-"}
        </p>
      ),
    },
  ]);
}

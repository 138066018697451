import CountUp from "react-countup";
import { DashboardNewUserFilterButton } from "components/DashboardFilterButton";
import {
  IDashboardCountWithPercent,
  IDashboardNewUserFilter,
  IDashboardPersonType,
} from "models/dashboard";
import PersonTypeFilterButton from "components/PersonTypeFilterButton";
import { useEffect, useRef, useState } from "react";

interface IProps {
  data: IDashboardCountWithPercent;
  handleTypeChange: (
    personType: IDashboardPersonType,
    type: IDashboardNewUserFilter
  ) => Promise<void>;
}

export default function TotalPersonCard({ data, handleTypeChange }: IProps) {
  const [type, setType] = useState<{
    personType: IDashboardPersonType;
    type: IDashboardNewUserFilter;
  }>({ personType: "customer", type: "today" });
  const didMount = useRef<boolean>(false);

  useEffect(() => {
    if (didMount.current)
      handleTypeChange(type.personType, type.type).then(() => {});
    else didMount.current = true;
    // eslint-disable-next-line
  }, [type.personType, type.type]);

  return (
    <div className="flex flex-col p-4 bg-white min-w-52 max-w-72 rounded-lg gap-y-5 w-full shadow-md">
      <div className="flex flex-col gap-y-2">
        <div className="flex items-center justify-between gap-x-10">
          <p className="text-base font-normal text-dark-gray whitespace-nowrap">
            Total Customer
          </p>
          <PersonTypeFilterButton
            handleFilterChange={(personType) =>
              setType((prev) => ({ ...prev, personType }))
            }
          />
        </div>
        <h3 className="text-darker-gray font-semibold text-2xl">
          <CountUp end={data.count} />
        </h3>
      </div>
      <div className="flex items-center gap-x-28 justify-between">
        <DashboardNewUserFilterButton
          handleFilterChange={(type) => setType((prev) => ({ ...prev, type }))}
          showAll
          addDaily
        />
        <p className="text-sm font-normal text-blue">
          <CountUp end={data.percent} />%
        </p>
      </div>
    </div>
  );
}

import logo from "../../assets/image/Logo.png";
import EmailInput from "../../components/Form/EmailInput";
import useForgetPassword from "../../hooks/useForgetPassword";

const Forget: React.FC = () => {
  const { handleSubmit, onSubmit, control, loading } = useForgetPassword();

  return (
    <div className="w-full px-4 mb-8">
      <form
        onSubmit={handleSubmit(onSubmit)}
        className="max-w-[360px]  mt-16 mx-auto flex flex-col gap-8"
      >
        <div className="flex flex-col justify-center items-center  gap-y-2">
          <img src={logo} alt="logo" className="h-7 w-7" />
          <p className="uppercase text-lg font-bold tracking-widest">
            Melabooks
          </p>
        </div>
        <div className="w-full flex flex-col gap-y-3">
          <h2 className="text-3xl font-semibold text-center capitalize">
            verify your email
          </h2>
          <p className="text-base text-textColor capitalize text-center">
            An OTP has been sent to your email, please provide the code below.
          </p>
        </div>

        <div className="flex w-max-[360px] flex-col gap-5">
          <div className="w-full flex flex-col gap-y-2">
            <label
              htmlFor="email"
              className="capitalize text-textColor text-sm text-left"
            >
              email
            </label>

            <EmailInput
              control={control}
              name="email"
              placeholder="email@example.com"
            />
          </div>

          {/* button */}

          <button
            disabled={loading}
            onClick={handleSubmit(onSubmit)}
            className="w-full mb-4 py-3 px-5 outline-none border bg-btnColor rounded-lg capitalize text-white font-medium text-center"
          >
            Verify email
          </button>
        </div>
      </form>
    </div>
  );
};

export default Forget;

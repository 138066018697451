import {
  authorizeGetRequest,
  authorizePatchRequest,
  authorizePostRequest,
} from "api";
import { AccountSortType, IAccount, IAccountResponse, OrderType } from "models";

export const fetchAccountsApi = (
  page: number,
  perPage: number,
  search: string,
  sort: AccountSortType,
  order: OrderType
) => {
  return authorizeGetRequest<IAccountResponse>(
    `accounts?page=${page}&per_page=${perPage}&search=${search}&sort_field=${sort}${
      order ? `&order_type=${order}` : ""
    }`
  );
};

export const createAccountApi = (data: any) => {
  return authorizePostRequest<IAccount>("accounts", data);
};

export const updateAccountApi = (id: string, data: any) => {
  return authorizePatchRequest<any>(`accounts/${id}`, data);
};

export default function ArrowLeftIcon() {
  return (
    <svg
      width="16"
      height="16"
      viewBox="0 0 16 16"
      xmlns="http://www.w3.org/2000/svg"
      fill="currentColor"
    >
      <mask
        id="mask0_4549_17406"
        style={{ maskType: "alpha" }}
        maskUnits="userSpaceOnUse"
        x="0"
        y="0"
        width="16"
        height="16"
      >
        <rect width="16" height="16" transform="matrix(-1 0 0 1 16 0)" />
      </mask>
      <g mask="url(#mask0_4549_17406)">
        <path d="M6.66634 12L7.59967 11.0333L5.23301 8.66667H13.333V7.33333H5.23301L7.59967 4.96667L6.66634 4L2.66634 8L6.66634 12Z" />
      </g>
    </svg>
  );
}

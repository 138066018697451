import ApexChart from "react-apexcharts";

interface IProps {
  totalSupplier: number;
  totalCustomer: number;
}

export default function CustomerSupplierOverview({
  totalCustomer,
  totalSupplier,
}: IProps) {
  return (
    <div className="w-full sm:w-8/12 sm:min-w-72 bg-white rounded-lg p-4 flex flex-col gap-y-6">
      <h6 className="text-darker-gray text-lg">Customer/Supplier Overview</h6>
      <ApexChart
        height={300}
        type="donut"
        width="100%"
        options={{
          chart: { background: "transparent" },
          colors: ["#15b79f", "#fb9c0c"],
          dataLabels: { enabled: false },
          labels: ["Customer", "Supplier"],
          legend: { show: false },
          plotOptions: { pie: { expandOnClick: true } },
          states: {
            active: { filter: { type: "none" } },
            hover: { filter: { type: "none" } },
          },
          stroke: { width: 0 },
          tooltip: { fillSeriesColor: false },
        }}
        series={[totalCustomer, totalSupplier]}
      />
      <div className="w-full flex items-center gap-x-6 justify-center">
        <div className="flex gap-x-2 items-center">
          <div className="w-3 h-3 rounded-full bg-[#15b79f]"></div>
          <span className="text-sm font-normal text-dark-gray block whitespace-nowrap">
            Customer
          </span>
        </div>
        <div className="flex gap-x-2 items-center">
          <div className="w-3 h-3 rounded-full bg-[#fb9c0c]"></div>
          <span className="text-sm font-normal text-dark-gray block whitespace-nowrap">
            Supplier
          </span>
        </div>
      </div>
    </div>
  );
}

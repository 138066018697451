import { authorizeGetRequest } from "api";
import {
  ITransactionResponse,
  IUserTransactionResponse,
} from "models/transaction";

export const userCashInTransactionsApi = (
  id: string,
  page: number,
  perPage: number,
  search: string
) => {
  return authorizeGetRequest<IUserTransactionResponse>(
    `transactions/user/${id}/cash-in?page=${page}&per_page=${perPage}&query=${search}`
  );
};

export const userCashOutTransactionsApi = (
  id: string,
  page: number,
  perPage: number,
  search: string
) => {
  return authorizeGetRequest<IUserTransactionResponse>(
    `transactions/user/${id}/cash-out?page=${page}&per_page=${perPage}&query=${search}`
  );
};

export const transactionsApi = (
  page: number,
  perPage: number,
  search: string
) => {
  return authorizeGetRequest<ITransactionResponse>(
    `transactions?page=${page}&per_page=${perPage}&query=${search}`
  );
};

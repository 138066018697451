import logo from "../../assets/image/Logo.png";
import PasswordInput from "../../components/Form/PasswordInput";
import useReset from "../../hooks/useReset";

const Reset: React.FC = () => {
  const { handleSubmit, onSubmit, control, loading } = useReset();

  return (
    <div className="w-full px-4 mb-8">
      {/* password Reset form */}

      <form
        onSubmit={handleSubmit(onSubmit)}
        className="max-w-[360px]  mt-16 mx-auto flex flex-col gap-8"
      >
        <div className="flex flex-col justify-center items-center  gap-y-2">
          <img src={logo} alt="logo" className="h-7 w-7" />
          <p className="uppercase text-lg font-bold tracking-widest">
            Melabooks
          </p>
        </div>
        <div className="w-full flex flex-col gap-y-3">
          <h2 className="text-3xl font-semibold text-center capitalize">
            Reset Password
          </h2>
          <p className="text-base text-textColor capitalize text-center">
            Oops you forgot your password, create a new password
          </p>
        </div>

        <div className="flex w-max-[360px] flex-col gap-5">
          <div className="w-full flex flex-col gap-y-2">
            <label
              htmlFor="password"
              className="capitalize text-textColor text-sm text-left"
            >
              New password
            </label>

            <PasswordInput
              control={control}
              name="password"
              placeholder="Enter your password"
            />
          </div>
          <div className="w-full flex flex-col gap-y-3">
            <label
              htmlFor="confirm_password"
              className="capitalize text-textColor text-sm text-left"
            >
              Confirm password
            </label>

            <PasswordInput
              control={control}
              name="password_confirmation"
              placeholder="Enter your new password"
            />
          </div>

          {/* button */}

          <button
            disabled={loading}
            onClick={handleSubmit(onSubmit)}
            className="w-full mb-4 py-3 px-5 outline-none border bg-btnColor rounded-lg capitalize text-white font-medium text-center"
          >
            create a new password
          </button>
        </div>
      </form>
    </div>
  );
};

export default Reset;

import { IUser } from "./user";

export interface IUserDebt extends IUserDebtResponse {
  loading: boolean;
}

export const userDebtDefault: IUserDebt = {
  data: { data: [], page: 1, per_page: 10, total: 0 },
  loading: true,
};

export interface IDebt {
  id: string;
  user_id: string;
  business_id: string;
  created_at: string;
  updated_at: string;
  transaction_id: string;
  person_id: string;
  name: string;
  total: number;
  is_supplied: boolean;
  did_i_owe: boolean;
  paid: number;
  borrowed_date: string;
  pay_back_date: string;
  payment_histories: IDebtPaymentHistory[];
}

export interface IDebtPaymentHistory {}

export interface IUserDebtResponse {
  data: IDebtResponse;
  user?: IUser;
}

export interface IDebtResponse {
  per_page: number;
  page: number;
  total: number;
  data: IDebt[];
}

export interface IDebts extends IDebtResponse {
  loading: boolean;
}

export const debtsDefault: IDebts = {
  data: [],
  page: 1,
  per_page: 10,
  total: 0,
  loading: true,
};

export const userDebtsDefault: IUserDebt = {
  data: { data: [], page: 1, per_page: 10, total: 0 },
  loading: true,
};

import ApexChart from "react-apexcharts";

interface IProps {
  data: { categories: string[]; value: ApexAxisChartSeries };
}

export default function TransactionOverview({
  data: { categories, value },
}: IProps) {
  return (
    <div className="w-full bg-white rounded-lg p-4 flex flex-col gap-y-6">
      <h6 className="text-darker-gray text-lg">Transaction Overview</h6>
      <div className="w-full flex items-center gap-x-6">
        <div className="flex gap-x-2 items-center">
          <div className="w-3 h-3 rounded-full bg-dark-blue"></div>
          <span className="text-sm font-normal text-dark-gray block whitespace-nowrap">
            Cash Out
          </span>
        </div>
        <div className="flex gap-x-2 items-center">
          <div className="w-3 h-3 rounded-full bg-dark-blue opacity-45"></div>
          <span className="text-sm font-normal text-dark-gray block whitespace-nowrap">
            Cash In
          </span>
        </div>
      </div>
      <div className="w-full">
        <ApexChart
          height={350}
          options={{
            chart: {
              background: "transparent",
              stacked: false,
              toolbar: { show: false },
            },
            colors: ["#0000A1", "#0000A145"],
            dataLabels: { enabled: false },
            fill: { opacity: 1, type: "solid" },
            grid: {
              borderColor: "#b6b6b6",
              strokeDashArray: 2,
              xaxis: { lines: { show: false } },
              yaxis: { lines: { show: true } },
            },
            legend: { show: false },
            plotOptions: { bar: { columnWidth: "40px" } },
            stroke: { colors: ["transparent"], show: true, width: 2 },
            theme: { mode: "light" },
            xaxis: {
              axisBorder: { color: "#dddddd", show: true },
              axisTicks: { color: "#dddddd", show: true },
              categories,
              labels: { offsetY: 5, style: { colors: "#667085" } },
            },
            yaxis: {
              labels: {
                formatter: (value) =>
                  value > 0 ? `${value} Birr` : `${value}`,
                offsetX: -10,
                style: { colors: "#667085" },
              },
            },
          }}
          series={value}
          type="bar"
          width="100%"
        />
      </div>
    </div>
  );
}

import { yupResolver } from "@hookform/resolvers/yup";
import { IResetPassword } from "../models/auth";

import { useForm, SubmitHandler } from "react-hook-form";
import { useNavigate, useParams } from "react-router-dom";
import resetValidator from "../validator/resetValidator";
import { resetPasswordApi } from "api";
import { useNotificationContext } from "contexts/NotificationContext";
import { useState } from "react";

export default function useReset() {
  const { token } = useParams();
  const navigate = useNavigate();
  const [loading, setLoading] = useState<boolean>(false);
  const { showError, showSuccess } = useNotificationContext();
  const { control, handleSubmit } = useForm<IResetPassword>({
    resolver: yupResolver(resetValidator),
  });

  const onSubmit: SubmitHandler<IResetPassword> = async (data) => {
    setLoading(true);
    resetPasswordApi({ ...data, token } as any)
      .then(async (response: any) => {
        showSuccess(response.message);
        if (response?.status === 200) {
          setTimeout(() => navigate("/"), 2000);
        }
      })
      .catch((error: any) =>
        showError(error?.response?.data?.message ?? "Network error")
      );
  };
  return { onSubmit, handleSubmit, control, loading };
}

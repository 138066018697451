import { useState, useEffect, ChangeEvent } from "react";
import { fetchUsersApi } from "api/user";
import { useNotificationContext } from "contexts/NotificationContext";
import { appendKey } from "helpers/table";
import { IUser, IUserData, UserSortType } from "models/user";
import { useNavigate } from "react-router-dom";
import { RouteName } from "routes";
import { OrderType } from "models";

export default function useUser() {
  const navigate = useNavigate();
  const { showError } = useNotificationContext();
  const [search, setSearch] = useState<string>("");
  const [users, setUsers] = useState<IUserData>({
    data: [],
    loading: true,
    page: 1,
    per_page: 10,
    total: 0,
  });

  useEffect(() => {
    fetchData(UserSortType.created_at, OrderType.descend);
    // eslint-disable-next-line
  }, [users.page, search]);

  const fetchData = async (sort: UserSortType, order: OrderType) => {
    setUsers((prev) => ({ ...prev, loading: true }));
    const { data, error } = await fetchUsersApi(
      users.page,
      users.per_page,
      search,
      sort,
      order
    );
    if (data) {
      data.data = appendKey(data.data);
      setUsers({ ...data, loading: false });
    } else showError(error!.message);
  };

  const handleSortChange = async (_: any, __: any, { field, order }: any) => {
    if (order) await fetchData(field, order);
  };

  const handlePageChange = (page: number) =>
    setUsers((prev) => ({ ...prev, loading: true, page }));

  const handleRowClick = (user: IUser) => ({
    onClick: () => navigate(`${RouteName.Users}/${user.id}`),
  });

  const handleSearch = ({
    target: { value },
  }: ChangeEvent<HTMLInputElement>) => {
    setSearch(value);
  };

  return {
    handlePageChange,
    handleRowClick,
    users,
    handleSearch,
    handleSortChange,
  };
}

import { yupResolver } from "@hookform/resolvers/yup";
import { Button, Modal } from "antd";
import { createCountryApi } from "api/country";
import ImageInput from "components/Form/ImageInput";
import TextInput from "components/Form/TextInput";
import { useNotificationContext } from "contexts/NotificationContext";
import { ICreateCountry } from "models/country";
import { useState } from "react";
import { useForm } from "react-hook-form";
import createCountryValidator from "validator/createCountryValidator";

interface IProps {
  handleClose: () => void;
  open: boolean;
  reloadData: () => void;
}

export default function CreateModal({ handleClose, open, reloadData }: IProps) {
  const [loading, setLoading] = useState<boolean>(false);
  const { showError, showSuccess } = useNotificationContext();
  const { control, handleSubmit } = useForm<ICreateCountry>({
    resolver: yupResolver(createCountryValidator),
  });
  const onSubmit = async (input: ICreateCountry) => {
    setLoading(true);
    const { data, error } = await createCountryApi(input);
    if (data) {
      reloadData();
      setLoading(false);
      handleClose();
      showSuccess("Country created successfully");
    } else {
      showError(error!.message);
      setLoading(false);
    }
  };

  return (
    <Modal
      maskClosable={false}
      open={open}
      onCancel={handleClose}
      centered={true}
      title="Create New Country"
      footer={null}
    >
      <form className="w-full mt-6">
        <div className="w-full flex flex-col gap-y-3">
          <label
            htmlFor="name"
            className="capitalize text-textColor text-sm text-left"
          >
            Name
          </label>
          <TextInput name="name" placeholder="Country name" control={control} />
        </div>
        <div className="w-full flex flex-col gap-y-3">
          <label
            htmlFor="short_name"
            className="capitalize text-textColor text-sm text-left"
          >
            Name
          </label>
          <TextInput
            control={control}
            name="short_name"
            placeholder="Country short name"
          />
        </div>
        <div className="w-full flex flex-col gap-y-3">
          <label
            htmlFor="code"
            className="capitalize text-textColor text-sm text-left"
          >
            Name
          </label>
          <TextInput control={control} name="code" placeholder="Country code" />
        </div>
        <div className="w-full flex flex-col gap-y-3">
          <label
            htmlFor="code"
            className="capitalize text-textColor text-sm text-left"
          >
            Flag
          </label>
          <ImageInput control={control} name="flag" />
        </div>
        <div className="w-full">
          <Button
            className="w-full flex justify-center items-center my-4 py-6 px-5 outline-none border bg-btnColor rounded-lg capitalize text-white font-medium text-center"
            key="submit"
            loading={loading}
            disabled={loading}
            onClick={handleSubmit(onSubmit)}
          >
            {loading ? "Creating..." : "Create"}
          </Button>
        </div>
      </form>
    </Modal>
  );
}

import axios from "axios";
import {
  IForgetPassword,
  IResetPassword,
  ISignInInput,
  IForgetResponse,
  ISignInResponse,
  IResetResponse,
} from "models/auth";

const BASE_URL = "https://staging.api.melabooks.et/api/v2/";

export function forgetPasswordApi(
  data: IForgetPassword
): Promise<IForgetResponse | IForgetPassword> {
  return new Promise((resolver, reject) => {
    axios
      .post(`${BASE_URL}auth/admin/forgot-password`, data)
      .then(async (response) => {
        resolver(response.data);
      })
      .catch((error: any) => {
        reject(error?.response?.data?.message ?? "Network error");
      });
  });
}

export function signInApi(data: ISignInInput): Promise<ISignInResponse> {
  return new Promise((resolver, reject) => {
    axios
      .post(`${BASE_URL}auth/admin/login`, data)
      .then(async (response) => {
        resolver(response.data);
        sessionStorage.setItem("token", response.data.token);
      })
      .catch((error: any) => {
        reject(error?.response?.data?.message ?? "Network error");
      });
  });
}

export function resetPasswordApi(
  data: IResetPassword
): Promise<IResetResponse | IResetPassword> {
  return new Promise((resolver, reject) => {
    axios
      .post(`${BASE_URL}auth/admin/reset-password`, data)
      .then(async (response) => {
        resolver(response.data);
      })
      .catch((error: any) => {
        reject(error?.response?.data?.message ?? "Network error");
      });
  });
}

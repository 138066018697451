import { Dropdown } from "antd";
import ArrowDownIcon from "./icons/ArrowDownIcon";
import { useMemo, useState } from "react";
import { IDashboardFilter, IDashboardNewUserFilter } from "models/dashboard";

interface IProps {
  handleFilterChange: (value: IDashboardFilter) => void;
  defaultVAlue?: IDashboardFilter;
  addDaily?: boolean;
}

export default function DashboardFilterButton({
  handleFilterChange,
  defaultVAlue,
  addDaily,
}: IProps) {
  const [value, setValue] = useState<IDashboardFilter>(
    defaultVAlue ?? "weekly"
  );
  const handleOnClick = (value: IDashboardFilter) => {
    setValue(value);
    handleFilterChange(value);
  };

  const items = useMemo(() => {
    const items = [];
    if (addDaily)
      items.push({
        key: "4",
        onClick: () => handleOnClick("daily"),
        label: <Label text={"Daily"} />,
      });
    items.push(
      ...[
        {
          key: "0",
          onClick: () => handleOnClick("weekly"),
          label: <Label text={"Weekly"} />,
        },
        {
          key: "1",
          onClick: () => handleOnClick("monthly"),
          label: <Label text="Monthly" />,
        },
        {
          key: "2",
          onClick: () => handleOnClick("yearly"),
          label: <Label text="Yearly" />,
        },
      ]
    );
    return items;
    // eslint-disable-next-line
  }, []);

  return (
    <Dropdown menu={{ items }}>
      <button className="outline-none border-none text-dark-blue text-sm flex gap-2 items-center capitalize whitespace-nowrap">
        {addDaily ? "" : "This"} {value}
        <ArrowDownIcon />
      </button>
    </Dropdown>
  );
}

interface INewUserProps {
  handleFilterChange: (value: IDashboardNewUserFilter) => void;
  showAll?: boolean;
  addDaily?: boolean;
}
export function DashboardNewUserFilterButton({
  handleFilterChange,
  showAll,
  addDaily,
}: INewUserProps) {
  const [value, setValue] = useState<IDashboardNewUserFilter>("today");
  const handleOnClick = (value: IDashboardNewUserFilter) => {
    setValue(value);
    handleFilterChange(value);
  };

  const items = useMemo(() => {
    const items = [
      {
        key: "0",
        onClick: () => handleOnClick("today"),
        label: <Label text={"Today"} />,
      },
      {
        key: "1",
        onClick: () => handleOnClick("yesterday"),
        label: <Label text="Yesterday" />,
      },
      {
        key: "2",
        onClick: () => handleOnClick("this week"),
        label: <Label text="This Week" />,
      },
      {
        key: "4",
        onClick: () => handleOnClick("previous week"),
        label: <Label text="Previous Week" />,
      },
      {
        key: "3",
        onClick: () => handleOnClick("the last 7 days"),
        label: <Label text="The Last 7 Days" />,
      },
      {
        key: "5",
        onClick: () => handleOnClick("previous 60 days"),
        label: <Label text="Previous 60 Days" />,
      },
      {
        key: "6",
        onClick: () => handleOnClick("previous 90 days"),
        label: <Label text="Previous 90 Days" />,
      },
      {
        key: "7",
        onClick: () => handleOnClick("this year"),
        label: <Label text="This year" />,
      },
    ];
    if (showAll)
      items.push({
        key: "8",
        onClick: () => handleOnClick("all time"),
        label: <Label text="All Time" />,
      });
    return items;
    // eslint-disable-next-line
  }, []);

  return (
    <Dropdown menu={{ items }}>
      <button className="outline-none border-none text-dark-blue text-sm flex gap-2 items-center capitalize whitespace-nowrap">
        {addDaily ? "" : "This"} {value}
        <ArrowDownIcon />
      </button>
    </Dropdown>
  );
}
function Label({ text }: { text: string }) {
  return (
    <span className="text-dark-gray text-sm py-1 px-1 font-medium capitalize">
      {text}
    </span>
  );
}

import { Table, TableColumnsType } from "antd";
import SearchInput from "components/Form/SearchInput";
import PaginationComponent from "components/PaginationComponent";
import { tableColumns } from "helpers/table";
import moment from "moment";
import { IInventory } from "models/inventory";
import useInventory from "hooks/useInventory";
import { IUser } from "models/user";

export default function Inventory() {
  const {
    handlePageChange,
    handleSearch,
    data: { loading, data, page, per_page, total },
  } = useInventory();
  return (
    <div className="w-full">
      <div className="w-full rounded-lg bg-white px-4 py-5 flex flex-col gap-y-4">
        <div className="w-full flex flex-col sm:flex-row items-center justify-between gap-6">
          <h1 className="text-black text-2xl font-normal w-full whitespace-nowrap">
            Inventories
          </h1>
          <SearchInput onChange={handleSearch} placeholder="Search Cash Out" />
        </div>
        <div className="w-full overflow-x-auto">
          <Table
            columns={columns()}
            dataSource={data}
            loading={loading}
            pagination={{ position: ["none"] }}
          />
        </div>

        <PaginationComponent
          loading={loading}
          page={page}
          perPage={per_page}
          total={total}
          handlePageChange={handlePageChange}
        />
      </div>
    </div>
  );
}

function columns(): TableColumnsType<IInventory> {
  return tableColumns<IInventory>([
    { title: "Name" },
    {
      title: "Owner",
      dataIndex: "user",
      render: (user?: IUser) => (
        <p className="text-[#303030] font-normal text-base">
          {user?.first_name} {user?.last_name}
        </p>
      ),
    },
    { title: "Cost" },
    { title: "Selling" },
    { title: "Quantity" },
    {
      title: "Created At",
      render: (date: string) => (
        <p className="text-[#303030] font-normal text-base">
          {moment(date).format("MMM DD, YYYY") ?? "-"}
        </p>
      ),
    },
  ]);
}

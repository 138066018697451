import { authorizeGetRequest } from "api";
import {
  IDashboardCountResponse,
  IDashboardNewUserFilter,
  IDashboardPersonType,
  IDashboardResponse,
  IDashboardTransactionResponse,
  IDashboardTransactionType,
  IUserRegisterResponse,
} from "models/dashboard";

export const dashboardApi = () => {
  return authorizeGetRequest<IDashboardResponse>("dashboard");
};

export const dashboardTransaction = (type: IDashboardNewUserFilter) => {
  return authorizeGetRequest<IDashboardTransactionResponse>(
    `dashboard/transaction-overview?type=${type}`
  );
};

export const newUsersApi = (type: IDashboardNewUserFilter) => {
  return authorizeGetRequest<IDashboardCountResponse>(
    `dashboard/new-users?type=${type}`
  );
};

export const activeUsersApi = (type: IDashboardNewUserFilter) => {
  return authorizeGetRequest<IDashboardCountResponse>(
    `dashboard/active-users?type=${type}`
  );
};

export const transactionApi = (
  filterType: IDashboardNewUserFilter,
  transactionType: IDashboardTransactionType
) => {
  return authorizeGetRequest<IDashboardCountResponse>(
    `dashboard/transactions?filter_type=${filterType}&transaction_type=${transactionType}`
  );
};

export const personApi = (
  filterType: IDashboardNewUserFilter,
  personType: IDashboardPersonType
) => {
  return authorizeGetRequest<IDashboardCountResponse>(
    `dashboard/persons?filter_type=${filterType}&person_type=${personType}`
  );
};

export const userRegisteredApi = (type: IDashboardNewUserFilter) => {
  return authorizeGetRequest<IUserRegisterResponse>(
    `dashboard/user-registered?type=${type}`
  );
};

export const transactionCreatedApi = (type: IDashboardNewUserFilter) => {
  return authorizeGetRequest<IUserRegisterResponse>(
    `dashboard/transaction-created?type=${type}`
  );
};

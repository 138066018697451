import { yupResolver } from "@hookform/resolvers/yup";
import { createAccountApi } from "api/account";
import { useNotificationContext } from "contexts/NotificationContext";
import { IUserCreateInput } from "models";
import { useState } from "react";
import { useForm, SubmitHandler } from "react-hook-form";
import userCreateValidator from "validator/userCreateValidator";

export default function useModal() {
  const [loading, setLoading] = useState(false);
  const [open, setOpen] = useState(false);
  const { showError, showSuccess } = useNotificationContext();
  const { control, handleSubmit } = useForm<IUserCreateInput>({
    resolver: yupResolver(userCreateValidator),
    defaultValues: { type: "Admin" },
  });

  const onSubmit: SubmitHandler<IUserCreateInput> = async (input) => {
    setLoading(true);
    const { error } = await createAccountApi(input);
    if (error) showError(error.message);
    else {
      showSuccess("You have successfully create a user");
      handleCancel();
    }
  };

  const showModal = () => {
    setOpen(true);
  };

  const handleOk = () => {
    // setLoading(true);
    // setTimeout(() => {
    //   setOpen(false);
    //   setLoading(false);
    // }, 3000);
    handleSubmit(onSubmit);
  };

  const handleCancel = () => {
    setOpen(false);
  };
  return {
    setLoading,
    open,
    loading,
    setOpen,
    showModal,
    handleCancel,
    onSubmit,
    handleOk,
    handleSubmit,
    control,
  };
}

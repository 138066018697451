import { useEffect, useState } from "react";
import { CheckCircleOutlined, EditOutlined } from "@ant-design/icons";
import { findConfigApi, updateConfigVersionApi } from "api/config";
import { useNotificationContext } from "contexts/NotificationContext";

function AppConfig() {
  const [edit, setEdit] = useState(false);
  const [version, setVersion] = useState("");
  const [loading, setLoading] = useState<boolean>(false);
  const { showError } = useNotificationContext();

  useEffect(() => {
    fetchVersion();
    // eslint-disable-next-line
  }, []);

  const fetchVersion = async () => {
    const { error, data } = await findConfigApi();
    if (error) showError(error.message);
    else setVersion(data!.value.version);
  };

  const handleUpdateVersion = async () => {
    if (version.split(".").length !== 3) {
      showError(
        'Version must be a string of digits which are separated by "." ex: 1.0.0'
      );
      return;
    } else if (
      version
        .split(".")
        .some((value) => isNaN(Number(value)) || Number(value) > 999)
    ) {
      showError("Each part of the version must be less than 999");
      return;
    }

    setLoading(true);
    const { data, error } = await updateConfigVersionApi({
      value: { version },
    });
    if (error) showError(error.message);
    else setVersion(data!.value.version);
    setLoading(false);
  };

  return (
    <div className="w-full h-auto bg-white rounded-lg px-8 py-5 flex flex-col gap-y-5">
      <h1 className="text-3xl font-bold text-center capitalize">App config</h1>
      <div className="w-full flex flex-row py-3 px-5 outline-none  justify-between bg-emerald-50 rounded-xl capitalize text-black font-medium text-center">
        <div className="flex flex-row gap-x-4">
          <CheckCircleOutlined />
          <p>current app version is</p>
        </div>

        <EditOutlined
          onClick={() => setEdit(!edit)}
          className="hover:scale-105 hover:bg-emerald-200 rounded-full p-1"
        />
      </div>
      <div className={`${edit ? "hidden" : "flex flex-col"} gap-y-6 w-full`}>
        <input
          onChange={({ target: { value } }) => {
            setVersion(value);
          }}
          value={version}
          type="text"
          name="version"
          placeholder="version"
          className="w-full outline-none bg-white px-4 py-2 text-lg rounded-lg border border-borderColor"
        />
        <button
          disabled={loading}
          onClick={handleUpdateVersion}
          className="w-full py-3 px-5 outline-none border disabled:opacity-50 bg-btnColor rounded-xl capitalize text-white font-medium text-center disabled:cursor-not-allowed"
        >
          update
        </button>
      </div>
    </div>
  );
}

export default AppConfig;

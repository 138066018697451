import { authorizeGetRequest } from "api";
import { OrderType } from "models";
import {
  IUserDashboardResponse,
  IUserResponse,
  UserSortType,
} from "models/user";

export const fetchUsersApi = (
  page: number,
  perPage: number,
  search: string,
  sort: UserSortType,
  order: OrderType
) => {
  return authorizeGetRequest<IUserResponse>(
    `users?page=${page}&per_page=${perPage}&query=${search}&sort_field=${sort}${
      order ? `&order_type=${order}` : ""
    }`
  );
};

export const userDashboardApi = (id: string) => {
  return authorizeGetRequest<IUserDashboardResponse>(`users/${id}/dashboard`);
};

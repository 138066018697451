import logo from "../../assets/image/Logo.png";
import PasswordInput from "../../components/Form/PasswordInput";
import EmailInput from "../../components/Form/EmailInput";
import useSignIn from "../../hooks/useSignIn";
import { Link } from "react-router-dom";
import { RouteName } from "routes";

export default function SignIn() {
  const { handleSubmit, onSubmit, loading, control } = useSignIn();

  return (
    <div className="w-full px-4 mb-8">
      <form className="max-w-[360px]  mt-16 mx-auto flex flex-col gap-8">
        <div className="flex flex-col justify-center items-center  gap-y-2">
          <img src={logo} alt="logo" className="h-7 w-7" />
          <p className="uppercase text-lg font-bold tracking-widest">
            Melabooks
          </p>
        </div>
        <div className="w-full flex flex-col gap-y-3">
          <h2 className="text-3xl font-semibold text-center capitalize">
            Sign in to your account
          </h2>
          <p className="text-base text-textColor capitalize text-center">
            Welcome back! Please enter your details.
          </p>
        </div>

        <div className="flex w-max-[360px] flex-col gap-5">
          <div className="w-full flex flex-col gap-y-2">
            <label
              htmlFor="email"
              className="capitalize text-textColor text-sm text-left"
            >
              email
            </label>

            <EmailInput
              control={control}
              name="email"
              placeholder="email@example.com"
            />
          </div>
          <div className="w-full flex flex-col gap-y-3">
            <label
              htmlFor="password"
              className="capitalize text-textColor text-sm text-left"
            >
              Password
            </label>

            <PasswordInput
              control={control}
              name="password"
              placeholder="Enter new password"
            />
          </div>
          <Link
            to={RouteName.ForgetPassword}
            className="text-sm font-medium text-right my-1 capitalize text-dark-blue"
          >
            forget password?
          </Link>

          {/* button */}

          <button
            disabled={loading}
            onClick={handleSubmit(onSubmit)}
            className="w-full py-3 px-5 outline-none border disabled:opacity-50 bg-btnColor rounded-lg capitalize text-white font-medium text-center"
          >
            sign in
          </button>
        </div>
      </form>
    </div>
  );
}

import { yupResolver } from "@hookform/resolvers/yup";
import { notification } from "antd";
import { IForgetPassword } from "../models/auth";
import { useForm, SubmitHandler } from "react-hook-form";
import { useNavigate } from "react-router-dom";
import forgetValidator from "../validator/forgetValidator";
import { forgetPasswordApi } from "api";
import { useNotificationContext } from "contexts/NotificationContext";
import { useState } from "react";

export default function useForgetPassword() {
  const [contextHolder] = notification.useNotification();
  const navigate = useNavigate();
  const [loading, setLoading] = useState<boolean>(false);
  const { showError, showSuccess } = useNotificationContext();
  const { control, handleSubmit } = useForm<IForgetPassword>({
    resolver: yupResolver(forgetValidator),
  });

  const onSubmit: SubmitHandler<IForgetPassword> = async (data) => {
    setLoading(true);
    forgetPasswordApi(data)
      .then((response: any) => {
        showSuccess(response.message);
        navigate("/");
      })
      .catch((error: any) => {
        showError(error?.response?.data?.message);
      });
  };

  return { onSubmit, handleSubmit, contextHolder, control, loading };
}

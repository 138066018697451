import { ChangeEvent } from "react";

interface IProps {
  onChange: (e: ChangeEvent<HTMLInputElement>) => void;
  placeholder: string;
}

export default function SearchInput({ onChange, placeholder }: IProps) {
  return (
    <input
      onChange={onChange}
      placeholder={placeholder}
      className="placeholder:text-[#9E9E9E] text-black text-sm font-normal bg-[#FAFAFA] rounded px-3 py-3 border-none w-full max-w-md outline-none"
      type="search"
    />
  );
}

import { MenuOutlined, UserOutlined } from "@ant-design/icons";
import { Avatar } from "antd";
import { useLocation } from "react-router-dom";
import { RouteName } from "routes";

function DashHeader({ openSidebar }: { openSidebar: () => void }) {
  const path = useLocation().pathname;
  const pathName = () => {
    if (path === RouteName.Accounts) return "Accounts";
    else if (path === RouteName.DashBoard) return "Dashboard";
    else if (path === RouteName.Country) return "Countries";
    else if (path === RouteName.Users) return "Users";
    else if (path === RouteName.Businesses) return "Businesses";
    else if (path === RouteName.Debt) return "Debts";
    else if (path === RouteName.Inventory) return "Inventories";
    else if (path === RouteName.Person) return "Customers/Suppliers";
    else if (path === RouteName.Transaction) return "Transaction";
    else if (path === RouteName.Setting) return "Settings";
    else if (path.endsWith("businesses")) return "User Businesses";
    else if (path.endsWith("cash-ins")) return "User Cash Ins";
    else if (path.endsWith("cash-outs")) return "User Cash Outs";
    else if (path.endsWith("inventories")) return "User Inventories";
    else if (path.endsWith("suppliers")) return "User Suppliers";
    else if (path.endsWith("debts")) return "User Debts";
    else if (path.endsWith("customers")) return "User Customers";
    else if (path.startsWith(`${RouteName.Users}/`)) return "User Detail";
    else return "404";
  };

  return (
    <div className="flex items-center justify-between p-6 w-full">
      <div className="w-full flex gap-x-4 items-center">
        <button className="block sm:hidden" onClick={openSidebar}>
          <MenuOutlined />
        </button>
        <h1 className="text-2xl sm:text-5xl font-semibold capitalize text-left">
          {pathName()}
        </h1>
      </div>
      <Avatar size="default" icon={<UserOutlined />} />
    </div>
  );
}

export default DashHeader;

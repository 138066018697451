import { useState, useEffect } from "react";
import {
  activeUsersApi,
  dashboardApi,
  dashboardTransaction,
  newUsersApi,
  personApi,
  transactionApi,
  transactionCreatedApi,
  userRegisteredApi,
} from "api/general";
import { useNotificationContext } from "contexts/NotificationContext";
import {
  IDashboard,
  IDashboardPersonType,
  IDashboardTransactionType,
  IDashboardCountResponse,
  dashboardDefaultValue,
  IDashboardNewUserFilter,
} from "models/dashboard";
import { ChartHelper } from "helpers/chart";

export default function useDashboard() {
  const { showError } = useNotificationContext();
  const [data, setData] = useState<IDashboard>(dashboardDefaultValue);

  useEffect(() => {
    dashboardApi().then(({ data: response, error }) => {
      if (response) {
        const transaction = [...data.transactions];
        transaction[0].value = response.cash_out_count;
        transaction[1].value = response.cash_in_count;
        transaction[2].value = response.debt_count;
        setData({
          newUser: response!.new_users,
          activeUsers: response.active_users,
          totalUsers: response.total_users,
          userChart: ChartHelper.userChartFromDateValueMap(
            response.user_registered,
            "today"
          ),
          transactions: transaction,
          personCard: IDashboardCountResponse.data(response.person),
          transactionCard: IDashboardCountResponse.data(response.transaction),
          transactionChart: ChartHelper.userChartFromDateValueMap(
            response.transaction_created,
            "today"
          ),
          userGraph: {
            data: ChartHelper.userChartFromDateValueMap(
              response.user_registered,
              "today"
            ),
            percent: ChartHelper.percentFromDateValueMap(
              response.user_registered,
              response.total_users
            ),
          },
        });
      } else showError(error!.message);
    });
    // eslint-disable-next-line
  }, []);

  const handleTransactionTypeChange = async (
    transactionType: IDashboardTransactionType,
    type: IDashboardNewUserFilter
  ) => {
    const { data, error } = await transactionApi(type, transactionType);
    if (data) {
      setData((prev) => ({
        ...prev,
        transactionCard: {
          count: data.count,
          percent: (data.count * 100) / data.total,
        },
      }));
    } else showError(error!.message);
  };
  const handleNewUserChange = async (type: IDashboardNewUserFilter) => {
    const { data, error } = await newUsersApi(type);
    if (data) {
      setData((prev) => ({ ...prev, newUser: data.count }));
    } else showError(error!.message);
  };
  // TODO: new user and active user switched
  const handleActiveUserChange = async (type: IDashboardNewUserFilter) => {
    const { data, error } = await activeUsersApi(type);
    if (data) {
      setData((prev) => ({ ...prev, activeUsers: data.count }));
    } else showError(error!.message);
  };

  const handlePersonTypeChange = async (
    personType: IDashboardPersonType,
    type: IDashboardNewUserFilter
  ) => {
    const { data, error } = await personApi(type, personType);
    if (data) {
      setData((prev) => ({
        ...prev,
        personCard: {
          count: data.count,
          percent: (data.count * 100) / data.total,
        },
      }));
    } else showError(error!.message);
  };

  const handleUserRegisterTypeChange = async (
    type: IDashboardNewUserFilter
  ) => {
    const { data, error } = await userRegisteredApi(type);
    if (data !== undefined) {
      setData((prev) => ({
        ...prev,
        userGraph: {
          data: ChartHelper.userChartFromDateValueMap(data.data, type),
          percent: ChartHelper.percentFromDateValueMap(
            data.data,
            prev.totalUsers
          ),
        },
      }));
    } else showError(error!.message);
  };

  const handleTransactionChartTypeChange = async (
    type: IDashboardNewUserFilter
  ) => {
    const { data, error } = await transactionCreatedApi(type);
    if (data !== undefined) {
      setData((prev) => ({
        ...prev,
        transactionChart: ChartHelper.userChartFromDateValueMap(
          data.data,
          type
        ),
      }));
    } else showError(error!.message);
  };

  const handleTransactionChange = async (type: IDashboardNewUserFilter) => {
    const { data: response, error } = await dashboardTransaction(type);

    if (response) {
      const transaction = [...data.transactions];
      transaction[0].value = response.cash_out_count;
      transaction[1].value = response.cash_in_count;
      transaction[2].value = response.debt_count;
      setData((prev) => ({ ...prev, transactions: transaction }));
    } else showError(error!.message);
  };

  const handleTransactionActiveChange = async (index: number) => {
    const transaction = data.transactions.map((value, key) => ({
      ...value,
      active: key === index ? true : false,
    }));
    setData((prev) => ({ ...prev, transactions: transaction }));
  };

  return {
    handleTransactionActiveChange,
    handleTransactionChange,
    handlePersonTypeChange,
    handleUserRegisterTypeChange,
    handleTransactionTypeChange,
    handleNewUserChange,
    handleActiveUserChange,
    handleTransactionChartTypeChange,
    data,
  };
}

import { IUser } from "./user";

export interface IBusiness {
  id: string;
  name: string;
  user_id: string;
  email?: string;
  category?: string;
  owner: string;
  description: string;
  state?: string;
  year: number;
  image?: string;
  created_at: string;
  updated_at: string;
}

export interface IUserBusinessResponse {
  data: IBusinessResponse;
  user?: IUser;
}

export interface IBusinessResponse {
  per_page: number;
  page: number;
  total: number;
  data: IBusiness[];
}

export interface IUserBusiness extends IUserBusinessResponse {
  loading: boolean;
}

export interface IBusinesses extends IBusinessResponse {
  loading: boolean;
}

export const businessesDefault: IBusinesses = {
  data: [],
  page: 1,
  per_page: 10,
  total: 0,
  loading: true,
};

export const userBusinessDefault: IUserBusiness = {
  data: { data: [], page: 1, per_page: 10, total: 0 },
  loading: true,
};

export enum BusinessSortType {
  name = "name",
  year = "year",
  created_at = "created_at",
}

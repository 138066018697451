import { Select } from "antd";
import { Controller } from "react-hook-form";

const options = [
  { value: "Admin", label: "Admin" },
  { value: "SuperAdmin", label: "super-admin" },
];

interface IProps {
  control: any;
  name: string;
  defaultValue?: string;
}

export default function SelectInput({ control, name, defaultValue }: IProps) {
  return (
    <Controller
      name={name}
      control={control}
      defaultValue={defaultValue ?? ""}
      render={({ field, fieldState: { error } }) => (
        <div className="w-full">
          <Select
            className="w-full outline-none bg-white text-lg border-none"
            {...field}
            options={options}
          />
          <p
            className="text-sm font-normal text-left mt-1 capitalize"
            style={{ color: "red" }}
          >
            {error?.message}
          </p>
        </div>
      )}
    />
    // <p>{errors.status?.message || errors.status?.label.message}</p>
  );
}

export default function ArrowMoreUpIcon() {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <mask
        id="mask0_4581_19800"
        style={{ maskType: "alpha" }}
        maskUnits="userSpaceOnUse"
        x="0"
        y="0"
        width="24"
        height="24"
      >
        <rect width="24" height="24" fill="#0000A1" />
      </mask>
      <g mask="url(#mask0_4581_19800)">
        <path
          d="M12 20V11H3V9H14V20H12ZM17 15V6H8V4H19V15H17Z"
          fill="#0000A1"
        />
      </g>
    </svg>
  );
}

import { Dropdown } from "antd";
import ArrowDownIcon from "./icons/ArrowDownIcon";
import { useMemo, useState } from "react";
import { IDashboardPersonType } from "models/dashboard";

interface IProps {
  handleFilterChange: (value: IDashboardPersonType) => void;
}

export default function PersonTypeFilterButton({ handleFilterChange }: IProps) {
  const [value, setValue] = useState<IDashboardPersonType>("customer");
  const handleOnClick = (value: IDashboardPersonType) => {
    setValue(value);
    handleFilterChange(value);
  };
  const items = useMemo(() => {
    const items = [
      {
        key: "0",
        onClick: () => handleOnClick("customer"),
        label: <Label text={"Customer"} />,
      },
      {
        key: "1",
        onClick: () => handleOnClick("supplier"),
        label: <Label text="Supplier" />,
      },
    ];
    return items;
    // eslint-disable-next-line
  }, []);

  return (
    <Dropdown menu={{ items }}>
      <button className="outline-none border-none text-blue text-sm flex gap-2 items-center capitalize">
        {value}
        <ArrowDownIcon />
      </button>
    </Dropdown>
  );
}

function Label({ text }: { text: string }) {
  return (
    <span className="text-dark-gray text-sm py-1 px-1 font-medium capitalize">
      {text}
    </span>
  );
}

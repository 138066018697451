import { DashboardNewUserFilterButton } from "components/DashboardFilterButton";
import DotIcon from "components/icons/DotIcon";
import { IDashboardNewUserFilter, IUserGraph } from "models/dashboard";
import CountUp from "react-countup";
import {
  LineChart,
  Line,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  ResponsiveContainer,
} from "recharts";

interface IProps {
  data: IUserGraph;
  handleFilterChange: (type: IDashboardNewUserFilter) => Promise<void>;
}

export default function UserRegistrationOverview({
  data,
  handleFilterChange,
}: IProps) {
  return (
    <div className="w-full bg-white rounded-lg p-4 max-w-4xl">
      <div className="w-full flex items-center gap-5 justify-between">
        <h6 className="text-darker-gray text-lg">User Registration Overview</h6>
        <DashboardNewUserFilterButton
          handleFilterChange={handleFilterChange}
          addDaily
        />
      </div>
      <div className="w-full mt-4">
        <div className="w-full flex items-center gap-x-16">
          <Description
            title="Users"
            count={data.data.reduce((prev, curr) => prev + curr.user, 0)}
            percent={data.percent}
            color="text-dark-blue"
          />
        </div>
      </div>
      <div className="w-full mt-6">
        <ResponsiveContainer width="100%" height={350}>
          <LineChart
            data={data.data}
            margin={{
              top: 16,
              right: 16,
              left: 16,
              bottom: 16,
            }}
          >
            <CartesianGrid strokeDasharray="3 3" />
            <XAxis dataKey="name" />
            <YAxis />
            <Tooltip />
            <Line
              type="monotone"
              dataKey="user"
              stroke="#0000A1"
              activeDot={{ r: 8 }}
              strokeWidth={2}
            />
          </LineChart>
        </ResponsiveContainer>
      </div>
    </div>
  );
}

interface IDescriptionProps {
  color: "text-dark-blue" | "text-orange" | "text-green";
  title: string;
  count: number;
  percent: number;
}

function Description({ color, count, percent, title }: IDescriptionProps) {
  return (
    <div className="flex flex-col gap-y-2 items-start">
      <div className="flex items-center gap-x-2">
        <span className={color}>
          <DotIcon />
        </span>
        <span className="text-base font-normal text-dark-gray">{title}</span>
      </div>
      <div className="flex gap-x-1 items-center">
        <p className="text-xl font-normal text-darker-gray">
          <CountUp end={count} />
        </p>
        <div className="px-1 border rounded-2xl border-blue text-blue text-xs">
          +<CountUp end={percent} />%
        </div>
      </div>
    </div>
  );
}

import { IUser } from "./user";

export interface IPerson {
  id: string;
  user_id: string;
  business_id: string;
  created_at: string;
  updated_at: string;
  is_customer: boolean;
  name: string;
  phone: string;
  email?: string;
  address?: string;
  state?: string;
  image: string;
}

export interface IUserPerson extends IUserPersonResponse {
  loading: boolean;
}

export const userPersonDefault: IUserPerson = {
  data: { data: [], page: 1, per_page: 10, total: 0 },
  loading: true,
};

export interface IUserPersonResponse {
  data: IPersonResponse;
  user?: IUser;
}

export interface IPersonResponse {
  per_page: number;
  page: number;
  total: number;
  data: IPerson[];
}

export interface IPeople extends IPersonResponse {
  loading: boolean;
}

export const userPeopleDefault: IPeople = {
  data: [],
  page: 1,
  per_page: 10,
  total: 0,
  loading: true,
};

export const peopleDefault: IPeople = {
  data: [],
  page: 1,
  per_page: 10,
  total: 0,
  loading: true,
};

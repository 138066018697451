import { Avatar } from "antd";
import { UserOutlined } from "@ant-design/icons";
import { IUser } from "models/user";
import moment from "moment";

interface IProps {
  data: IUser | undefined;
}

export default function UserInfo({ data }: IProps) {
  return (
    <div className="w-full flex flex-col sm:flex-row items-center gap-x-3">
      <div className="flex flex-col items-center sm:items-start gap-y-2">
        <div className="w-14 h-14 rounded-full overflow-clip shadow-md">
          <Avatar
            src={data?.image}
            icon={<UserOutlined className="text-3xl" />}
            alt="profile"
            className="w-full h-full"
          />
        </div>
        <h3 className="text-darker-gray text-2xl font-semibold whitespace-nowrap">
          {data?.first_name} {data?.last_name}
        </h3>
      </div>
      <div className="flex flex-col sm:flex-row ga-y-1 items-start gap-6 flex-wrap">
        <div className="flex flex-col gap-y-1">
          <p className="text-xs text-dark-gray">Phone</p>
          <p className="text-sm text-darker-gray font-semibold">
            {data?.phone}
          </p>
        </div>
        <div className="flex flex-col gap-y-1">
          <p className="text-xs text-dark-gray">Email</p>
          <p className="text-sm text-darker-gray font-normal">
            {data?.email ?? "not set"}
          </p>
        </div>
        <div className="flex flex-col gap-y-1">
          <p className="text-xs text-dark-gray">Registered at</p>
          <p className="text-sm text-darker-gray font-normal flex items-center gap-x-2">
            {data?.created_at
              ? moment(data?.created_at).format("ddd DD, MMM YYYY")
              : null}
          </p>
        </div>
        <div className="flex flex-col gap-y-1">
          <p className="text-xs text-dark-gray">Last login</p>
          <p className="text-sm text-darker-gray font-normal flex items-center gap-x-2">
            <span className="w-3 h-3 rounded-full bg-green"></span>
            {data?.updated_at ? moment(data?.updated_at).fromNow() : null}
          </p>
        </div>
      </div>
    </div>
  );
}

import moment from "moment";
import { useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import { userDashboardApi } from "api/user";
import { useNotificationContext } from "contexts/NotificationContext";
import { IUserDashboard, userDashboardDefault } from "models/user";

export default function useUserDetail() {
  const id: string = useParams().id!;
  const { showError } = useNotificationContext();
  const [data, setData] = useState<IUserDashboard>(userDashboardDefault);

  useEffect(() => {
    userDashboardApi(id).then(({ data, error }) => {
      if (data) {
        // extract dates and
        const cashIn = data.transaction.cash_in;
        const cashOut = data.transaction.cash_out;
        let dates: string[] = Object.keys(cashIn);
        dates.push(...Object.keys(cashOut));
        dates = Array.from(new Set(dates));
        setData({
          ...data,
          transaction: {
            categories: convertKeyToDate(dates),
            value: [
              { name: "Cash In", data: dates.map((key) => cashIn[key] ?? 0) },
              { name: "Cash Out", data: dates.map((key) => cashOut[key] ?? 0) },
            ],
          },
        });
      } else showError(error!.message);
    });
    // eslint-disable-next-line
  }, []);

  const convertKeyToDate = (value: string[]) =>
    value.map((value) => moment(value).format("MMM YYYY"));

  return { data, id };
}

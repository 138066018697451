import { countriesApi } from "api/country";
import { useNotificationContext } from "contexts/NotificationContext";
import { ICountry, ICountryData } from "models/country";
import { useEffect, useState } from "react";

export default function useCountry() {
  const { showError } = useNotificationContext();
  const [data, setData] = useState<ICountryData>({ loading: true, data: [] });
  const [openCreateModal, setOpenCreateModal] = useState<boolean>(false);
  const [openUpdateModal, setOpenUpdateModal] = useState<{
    open: boolean;
    country: ICountry | null;
  }>({ open: false, country: null });

  useEffect(() => {
    fetchCountries();
    // eslint-disable-next-line
  }, []);

  const fetchCountries = () => {
    countriesApi().then(({ data, error }) => {
      if (data) {
        setData({ data, loading: false });
      } else showError(error!.message);
    });
  };
  const reloadData = () => {
    setData({ loading: true, data: [] });
    fetchCountries();
  };

  const handleOpenCreateModal = () => setOpenCreateModal(true);
  const handleCloseCreateModal = () => setOpenCreateModal(false);
  const handleOpenUpdateModal = (country: ICountry) =>
    setOpenUpdateModal({ open: true, country });
  const handleCloseUpdateModal = () =>
    setOpenUpdateModal({ open: false, country: null });

  return {
    data,
    handleCloseCreateModal,
    handleOpenCreateModal,
    openCreateModal,
    reloadData,
    openUpdateModal,
    handleCloseUpdateModal,
    handleOpenUpdateModal,
  };
}

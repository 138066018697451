export type IUserChart = {
  name: string;
  user: number;
};

export interface IUserGraph {
  data: IUserChart[];
  percent: number;
}

export interface IDashboard {
  newUser: number;
  activeUsers: number;
  totalUsers: number;
  userChart: IUserChart[];
  userGraph: IUserGraph;
  transactionChart: IUserChart[];
  transactions: IDashboardTransaction[];
  personCard: IDashboardCountWithPercent;
  transactionCard: IDashboardCountWithPercent;
}

export interface IDashboardCountWithPercent {
  count: number;
  percent: number;
}

export interface IDashboardResponse {
  user_registered: { [key: string]: number };
  transaction_created: { [key: string]: number };
  transaction: IDashboardCountResponse;
  person: IDashboardCountResponse;
  total_users: number;
  active_users: number;
  new_users: number;
  cash_in_count: number;
  cash_out_count: number;
  debt_count: number;
}

export type IDashboardTransactionType = "cash_out" | "cash_in";
export type IDashboardPersonType = "customer" | "supplier";

export type IDashboardFilter = "daily" | "weekly" | "monthly" | "yearly";
export type IDashboardNewUserFilter =
  | "today"
  | "yesterday"
  | "this week"
  | "previous week"
  | "the last 7 days"
  | "previous 60 days"
  | "previous 90 days"
  | "this year"
  | "all time";

export class IDashboardCountResponse {
  readonly count: number;
  readonly total: number;

  constructor({ count, total }: { count: number; total: number }) {
    this.count = count;
    this.total = total;
  }

  public static data({ count, total }: { count: number; total: number }): {
    percent: number;
    count: number;
  } {
    const percent = (100 * count) / total;
    return { count: count, percent };
  }
}

export interface IDashboardTransactionResponse {
  cash_in_count: number;
  cash_out_count: number;
  debt_count: number;
}

export interface IDashboardTransaction {
  name: string;
  value: number;
  active: boolean;
}

export interface IUserRegisterResponse {
  data: { [key: string]: number };
}

export const dashboardDefaultValue: IDashboard = {
  newUser: 0,
  personCard: { count: 0, percent: 0 },
  totalUsers: 0,
  activeUsers: 0,
  transactionCard: { count: 0, percent: 0 },
  userChart: [],
  userGraph: { data: [], percent: 0 },
  transactionChart: [],
  transactions: [
    { name: "Cash Out", value: 0, active: false },
    { name: "Cash In", value: 0, active: false },
    { name: "Debt", value: 0, active: false },
  ],
};

import { authorizeGetRequest } from "api";
import { OrderType } from "models";
import {
  BusinessSortType,
  IBusinessResponse,
  IUserBusinessResponse,
} from "models/business";

export const userBusinessesApi = (
  id: string,
  page: number,
  perPage: number,
  search: string
) => {
  return authorizeGetRequest<IUserBusinessResponse>(
    `businesses/user/${id}?page=${page}&per_page=${perPage}&query=${search}`
  );
};

export const businessesApi = (
  page: number,
  perPage: number,
  search: string,
  sort: BusinessSortType,
  order: OrderType
) => {
  return authorizeGetRequest<IBusinessResponse>(
    `businesses?page=${page}&per_page=${perPage}&query=${search}&sort_field=${sort}${
      order ? `&order_type=${order}` : ""
    }`
  );
};

import * as yup from "yup";

const forgetValidator = yup
  .object({
    email: yup
      .string()
      .required("Email is required")
      .email("Enter a valid email"),
  })
  .required()

 export default forgetValidator;

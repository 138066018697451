import { Table, TableColumnsType } from "antd";
import { fetchAccountsApi } from "api/account";
import SearchInput from "components/Form/SearchInput";
import PaginationComponent from "components/PaginationComponent";
import StatusBadge from "components/StatusBadge";
import { useNotificationContext } from "contexts/NotificationContext";
import { appendKey, tableColumns } from "helpers/table";
import { AccountSortType, IAccount, IAccountData, OrderType } from "models";
import { ChangeEvent, useEffect, useState } from "react";
import useModal from "hooks/useModal";
import useUpdateModal from "hooks/useUpdateModal";
import CreateModal from "components/modals/CreateModal";
import UpdateModal from "components/modals/UpdateModal";

export default function Account() {
  const {
    open,
    handleSubmit,
    onSubmit,
    loading,
    control,
    showModal,
    handleCancel,
  } = useModal();
  const {
    open: updateOpen,
    showUpdateModal,
    handleUpdateCancel,
  } = useUpdateModal();
  const { showError } = useNotificationContext();
  const [search, setSearch] = useState<string>("");
  const [accounts, setAccounts] = useState<IAccountData>({
    data: [],
    page: 1,
    per_page: 10,
    total: 0,
    loading: true,
  });

  useEffect(() => {
    fetchData(AccountSortType.created_at, OrderType.descend);
    // eslint-disable-next-line
  }, [accounts.page, search]);

  const fetchData = async (sort: AccountSortType, order: OrderType) => {
    setAccounts((prev) => ({ ...prev, loading: true }));
    const { data, error } = await fetchAccountsApi(
      accounts.page,
      accounts.per_page,
      search,
      sort,
      order
    );

    if (data) {
      data.data = appendKey<IAccount>(data.data);
      setAccounts({ ...data, loading: false });
    } else showError(error!.message);
  };

  const handlePageChange = (page: number) =>
    setAccounts((prev) => ({ ...prev, page, loading: true }));

  const handleSearch = ({
    target: { value },
  }: ChangeEvent<HTMLInputElement>) => {
    setSearch(value);
  };

  const handleSortChange = async (_: any, __: any, { field, order }: any) => {
    if (order) await fetchData(field, order);
  };

  return (
    <div className="w-full">
      <div className="w-full rounded-lg bg-white px-4 py-5 flex flex-col gap-y-4">
        <div className="w-full flex flex-col sm:flex-row items-center justify-between gap-6">
          <h1 className="text-black text-2xl font-normal w-full whitespace-nowrap">
            All Accounts
          </h1>
          <SearchInput
            onChange={handleSearch}
            placeholder="Search admin by name"
          />

          <button
            onClick={showModal}
            className="hover:bg-btnColor transition-all duration-500 hover:text-white outline-none text-base font-semibold text-black whitespace-nowrap px-4 py-2 border-btnColor hover:border-dark-blue border-2 rounded-md w-full sm:w-fit"
          >
            Create Account
          </button>
          <CreateModal
            open={open}
            control={control}
            handleOk={handleSubmit(onSubmit)}
            loading={loading}
            handleCancel={handleCancel}
          />
        </div>

        <div className="w-full overflow-x-auto">
          <Table
            columns={columns(showUpdateModal)}
            dataSource={accounts.data}
            loading={accounts.loading}
            pagination={{ position: ["none"] }}
            onChange={handleSortChange}
          />
        </div>

        <PaginationComponent
          loading={accounts.loading}
          page={accounts.page}
          perPage={accounts.per_page}
          total={accounts.total}
          handlePageChange={handlePageChange}
        />
        <UpdateModal
          open={updateOpen}
          control={control}
          handleUpdateOk={handleSubmit(onSubmit)}
          loading={loading}
          handleUpdateCancel={handleUpdateCancel}
          emailValue="hello"
        />
      </div>
    </div>
  );
}

function columns(showUpdateModal: () => void): TableColumnsType<IAccount> {
  return tableColumns<IAccount>(
    [
      { title: "Email", sorter: true },
      { title: "Type", sorter: true },
      {
        title: "Status",
        dataIndex: "is_active",
        sorter: true,
        render: (isActive: boolean) => <StatusBadge isActive={isActive} />,
      },
    ],
    (account) => {
      const menus = [
        {
          label: "Edit",
          onClick: showUpdateModal,
        },
      ];
      if (account.is_active)
        menus.push({
          label: "Ban",
          onClick: () => {},
        });
      else
        menus.push({
          label: "Activate",
          onClick: () => {
            ("");
          },
        });
      return menus;
    }
  );
}

import * as yup from "yup";

const resetValidator = yup
  .object({
    password: yup.string().required("Password is required"),
    password_confirmation: yup
      .string()
      .label("confirm password")
      .required()
      .oneOf([yup.ref("password")], "Passwords must match"),
  })
  .required();

export default resetValidator;

import { Popconfirm } from "antd";
import { deleteCountryApi } from "api/country";
import DeleteIcon from "components/icons/DeleteIcon";
import { useNotificationContext } from "contexts/NotificationContext";
import { ICountry } from "models/country";
import { useState } from "react";

interface IProps {
  country: ICountry;
  reloadData: () => void;
}

export default function DeleteButton({ country, reloadData }: IProps) {
  const { showError, showSuccess } = useNotificationContext();
  const [value, setValue] = useState<{ open: boolean; loading: boolean }>({
    loading: false,
    open: false,
  });

  const handleOpen = () => setValue({ open: true, loading: false });
  const handleClose = () => setValue({ loading: false, open: false });

  const handleDeleteCountry = async () => {
    setValue((prev) => ({ ...prev, loading: true }));
    const { data, error } = await deleteCountryApi(country.id);
    if (data) {
      reloadData();
      handleClose();

      showSuccess("You have successfully deleted a country");
    } else {
      setValue((prev) => ({ ...prev, loading: false }));
      showError(error?.message!);
    }
  };
  return (
    <Popconfirm
      title="Delete Country"
      description="Are you sure you want to delete this country"
      open={value.open}
      placement="left"
      okType="danger"
      onConfirm={handleDeleteCountry}
      okButtonProps={{ loading: value.loading }}
      onCancel={handleClose}
    >
      <button
        onClick={handleOpen}
        className="hover:bg-rose-200 transition-all duration-500 text-rose-500 outline-none text-xl w-8 h-8 flex items-center justify-center rounded-full"
      >
        <DeleteIcon />
      </button>
    </Popconfirm>
  );
}

export interface IUser {
  id: string;
  created_at: string;
  updated_at: string;
  first_name: string;
  last_name: string;
  email: string;
  phone: string;
  is_daily_reminder_set: true;
  daily_reminder: string;
  is_report_reminder_set: true;
  report_reminder: string;
  report_weekday: string;
  report_type: string;
  image: string;
  is_active: true;
  banned_reason: string;
}

export interface IUserResponse {
  per_page: number;
  page: number;
  total: number;
  data: IUser[];
}

export interface IUserData extends IUserResponse {
  loading: boolean;
}

export interface IUserDashboardResponse {
  user?: IUser;
  total_business: number;
  total_customer: number;
  total_supplier: number;
  total_debt: number;
  total_cash_in: number;
  total_cash_out: number;
  total_inventory: number;
  transaction: {
    cash_in: { [key: string]: number };
    cash_out: { [key: string]: number };
  };
}

export interface IUserDashboard {
  user?: IUser;
  total_business: number;
  total_customer: number;
  total_supplier: number;
  total_debt: number;
  total_cash_in: number;
  total_cash_out: number;
  total_inventory: number;
  transaction: { categories: string[]; value: ApexAxisChartSeries };
}

export const userDashboardDefault: IUserDashboard = {
  total_business: 0,
  total_cash_in: 0,
  total_cash_out: 0,
  total_customer: 0,
  total_debt: 0,
  total_inventory: 0,
  total_supplier: 0,
  transaction: { categories: [], value: [] },
};

export enum UserSortType {
  first_name = "first_name",
  last_name = "last_name",
  email = "email",
  phone = "phone",
  created_at = "created_at",
}

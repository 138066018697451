export default function ArrowDownIcon() {
  return (
    <svg
      width="16"
      height="16"
      viewBox="0 0 16 16"
      fill="currentColor"
      xmlns="http://www.w3.org/2000/svg"
    >
      <mask
        id="mask0_4353_16131"
        style={{ maskType: "alpha" }}
        maskUnits="userSpaceOnUse"
        x="0"
        y="0"
        width="16"
        height="16"
      >
        <rect
          x="16"
          y="16"
          width="16"
          height="16"
          transform="rotate(-180 16 16)"
        />
      </mask>
      <g mask="url(#mask0_4353_16131)">
        <path d="M11.3333 6.66667L8.00001 10L4.66668 6.66667L11.3333 6.66667Z" />
      </g>
    </svg>
  );
}

import * as yup from "yup";

const signInValidator = yup
  .object({
    email: yup
      .string()
      .required("Email is required")
      .email("Enter a valid email"),
    password: yup.string().required("Password is required"),
  })
  .required();

export default signInValidator;

import { yupResolver } from "@hookform/resolvers/yup";
import { useForm, SubmitHandler } from "react-hook-form";
import { useNavigate } from "react-router-dom";
import { ISignInInput } from "models/auth";
import signInValidator from "validator/signInValidator";
import { signInApi } from "api";
import { useNotificationContext } from "contexts/NotificationContext";
import { RouteName } from "routes";
import { useState } from "react";

export default function useSignIn() {
  const [loading, setLoading] = useState<boolean>(false);
  const navigate = useNavigate();
  const { showError, showSuccess } = useNotificationContext();
  const { control, handleSubmit } = useForm<ISignInInput>({
    resolver: yupResolver(signInValidator),
  });

  const onSubmit: SubmitHandler<ISignInInput> = async (input) => {
    setLoading(true);
    signInApi(input)
      .then(({ token }) => {
        showSuccess("You have successfully logged in");
        localStorage.setItem("token", token);
        navigate(RouteName.DashBoard);
      })
      .catch((error: any) => {
        showError(error ?? "Network error");
      })
      .finally(() => setLoading(false));
  };

  return { onSubmit, handleSubmit, control, loading };
}

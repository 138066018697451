import { IUser } from "./user";

export interface IInventory {
  id: string;
  user_id: string;
  business_id: string;
  created_at: string;
  updated_at: string;
  person_id?: string;
  name: string;
  quantity: number;
  is_product: boolean;
  cost: number;
  selling: number;
  unit?: string;
  image?: string;
}

export interface IUserInventory extends IUserInventoryResponse {
  loading: boolean;
}

export const userInventoryDefault: IUserInventory = {
  data: { data: [], page: 1, per_page: 10, total: 0 },
  loading: true,
};

export interface IUserInventoryResponse {
  data: IInventoryResponse;
  user?: IUser;
}

export interface IInventoryResponse {
  per_page: number;
  page: number;
  total: number;
  data: IInventory[];
}

export interface IInventories extends IInventoryResponse {
  loading: boolean;
}

export const userInventoriesDefault: IInventories = {
  data: [],
  page: 1,
  per_page: 10,
  total: 0,
  loading: true,
};

export const inventoriesDefault: IInventories = {
  data: [],
  page: 1,
  per_page: 10,
  total: 0,
  loading: true,
};

export default function ArrowRightIcon() {
  return (
    <svg
      width="16"
      height="16"
      viewBox="0 0 16 16"
      fill="currentColor"
      xmlns="http://www.w3.org/2000/svg"
    >
      <mask
        id="mask0_4549_17403"
        style={{ maskType: "alpha" }}
        maskUnits="userSpaceOnUse"
        x="0"
        y="0"
        width="16"
        height="16"
      >
        <rect width="16" height="16" />
      </mask>
      <g mask="url(#mask0_4549_17403)">
        <path d="M9.33366 12L8.40033 11.0333L10.767 8.66667H2.66699V7.33333H10.767L8.40033 4.96667L9.33366 4L13.3337 8L9.33366 12Z" />
      </g>
    </svg>
  );
}
